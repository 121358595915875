import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BannerTitle from "../../comman/BannerTitle";
import { IndustryData } from "../../services/data";
import {
  IndustriesUl,
  InstriesWrapper,
} from "../../styles/Servicespages.style";

export const Industries = () => {
  return (
    <InstriesWrapper>
      <Container>
        <BannerTitle
          IsSectionTitle
          isSmallText
          MainTitle={<h2>Our iOS Apps Cover <br/> Heterogeneous Industries</h2>}
          Content={"We have created apps for several industrial categories:"}
        ></BannerTitle>
        <IndustriesUl>
          <Row>
            {IndustryData?.map((item, index) => {
              return (
                <>
                  <Col lg={4} md={6} sm={6}>
                    <div class="cover_wrap yellow d-flex align-items-center">
                      <div class="cover_img">{item.Image}</div>
                      <div class="cover_title">
                        <h3>{item.Title}</h3>
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </IndustriesUl>
      </Container>
    </InstriesWrapper>
  );
};
export default Industries;
