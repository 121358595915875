import React, { lazy } from "react";

export default (path) => {
  return lazy(() => {
    return Promise.all([
      path,
      new Promise((resolve) => setTimeout(resolve, 600)),
    ]).then(([moduleExports]) => moduleExports);
  });
};
