import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProcessData } from "../services/data";
import {
  ProcessInsideWrapper,
  ProcessWrapper,
} from "../styles/Servicespages.style";
import BannerTitle from "./BannerTitle";

export const DevelopmentProcess = () => {
  return (
    <ProcessWrapper>
      <Container>
         
        <ProcessInsideWrapper>
        <BannerTitle 
         IsSectionTitle
         isSmallText
          MainTitle="Our iPhone App Development Process" 
          Content ="We use the most advanced technologies, robust frameworks, and best industry practices, following a comprehensive development process for your iOS/iPhone app development."
          />
          {ProcessData?.map((item) => {
            return (
              <Row className="process_ul">
                <Col lg={2} md={3} sm={3} className="process_image_li">
                  <div className="process_image">
                    <img src={item.Image} className="img-fluid" />
                  </div>
                </Col>
                <Col lg={7} md={9} sm={9} className="process_desc_li">
                  <div className="process_desc left_block">
                    <div className="border_left">
                      <h3>{item.Title}</h3>
                      <p>{item.Text}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} className="cloud_image_li">
                  <div className="cloud_image">
                    <img src={item.Icon} className="img-fluid" />
                  </div>
                </Col>
              </Row>
            );
          })}
        </ProcessInsideWrapper>
      </Container>
    </ProcessWrapper>
  );
};
export default DevelopmentProcess;
