import React, { useEffect, useRef, useState } from 'react'
import { Col, Container } from 'react-bootstrap'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faLongArrowLeft } from '@fortawesome/free-solid-svg-icons'
// Import Swiper styles

import {
  TestomonialSlider,
  TestomonialSwiperArrow,
  TestomonialWrapper,
} from '../styles/globalStyles'
import BannerTitle from './BannerTitle'
// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper'

import ClientImg from '../assets/Service_pages_images/Client4.png'
import Images from '../assets/Images'

export const ClientTestomonial = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://tecocraft.co.in/webdesign-work/tecocraft-in/assets/js/slider.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <TestomonialWrapper>
      <Container>
        <Col lg={8} className="m-auto">
          <BannerTitle
            IsSectionTitle
            MainTitle={<h2>Client Testimonials</h2>}
            Content={
              'We have built a strong relationship, working partnership and true friendship while working with our amazing clients ranging from different industry verticals. Hear it straight from our valuable clients to know what they say about our services and the value we offer!'
            }
          />
        </Col>
        <TestomonialSlider>
          <div className="testi-wrap">
            <div
              className="client-single active position-1"
              data-position="position-1"
            >
              <div className="client-img yellow">
                <img src={Images.ClientImg1} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div class="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-2"
              data-position="position-2"
            >
              <div className="client-img red">
                <img src={Images.ClientImg2} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-3"
              data-position="position-3"
            >
              <div className="client-img blue">
                <img src={Images.ClientImg3} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info green">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-4"
              data-position="position-4"
            >
              <div className="client-img yellow">
                <img src={Images.ClientImg4} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-5"
              data-position="position-5"
            >
              <div className="client-img blue">
                <img src={Images.ClientImg5} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-6"
              data-position="position-6"
            >
              <div className="client-img red">
                <img src={Images.ClientImg6} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
            <div
              className="client-single inactive position-7"
              data-position="position-7"
            >
              <div className="client-img green">
                <img src={ClientImg} alt="" />
              </div>
              <div className="client-comment">
                <p>
                  This is one of the best freelancer's I have worked with. Her
                  work delivery is perfect. I will definitely use her again.
                  She's a good listener and pays attention to details. She is
                  definitely someone you need on your team. Good work, great
                  communicator. Will definitely use her again
                </p>
                <div className="quote-left">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="quote-right">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
              </div>
              <div className="client-info">
                <h3>Tiana Bergson</h3>
                <p>Tony Pryce</p>
              </div>
            </div>
          </div>
          <TestomonialSwiperArrow>
            <div className="slider-arrow prev">
              <button className="prev-btn">
                <FontAwesomeIcon icon={faLongArrowLeft} />
              </button>
            </div>
            <div className="slider-arrow next">
              <button className="next-btn">
                <FontAwesomeIcon icon={faLongArrowRight} />
              </button>
            </div>
          </TestomonialSwiperArrow>
          {/* <Swiper
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            <SwiperSlide>
              <div className="slide-content">
                <div className="client-img yellow">
                  <img src={ClientImg} className="img-fluid" />
                </div>
                <div className="client-comment">
                  <img src={Images.QuoteLeft} className="img-fluid left-img" />
                  <p>
                    This is one of the best freelancer's I have worked with. Her
                    work delivery is perfect. I will definitely use her again.
                    She's a good listener and pays attention to details. She is
                    definitely someone you need on your team. Good work, great
                    communicator. Will definitely use her again
                  </p>
                  <img
                    src={Images.QuoteRight}
                    className="img-fluid right-img"
                  />
                </div>
                <div className="client-info">
                  <h3>Tiana Bergson</h3>
                  <span>Tony Pryce</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content">
                <div className="client-img yellow">
                  <img src={ClientImg} className="img-fluid" />
                </div>
                <div className="client-comment">
                  <img src={Images.QuoteLeft} className="img-fluid left-img" />
                  <p>
                    This is one of the best freelancer's I have worked with. Her
                    work delivery is perfect. I will definitely use her again.
                    She's a good listener and pays attention to details. She is
                    definitely someone you need on your team. Good work, great
                    communicator. Will definitely use her again
                  </p>
                  <img
                    src={Images.QuoteRight}
                    className="img-fluid right-img"
                  />
                </div>
                <div className="client-info">
                  <h3>Tiana Bergson</h3>
                  <span>Tony Pryce</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content">
                <div className="client-img yellow">
                  <img src={ClientImg} className="img-fluid" />
                </div>
                <div className="client-comment">
                  <img src={Images.QuoteLeft} className="img-fluid left-img" />
                  <p>
                    This is one of the best freelancer's I have worked with. Her
                    work delivery is perfect. I will definitely use her again.
                    She's a good listener and pays attention to details. She is
                    definitely someone you need on your team. Good work, great
                    communicator. Will definitely use her again
                  </p>
                  <img
                    src={Images.QuoteRight}
                    className="img-fluid right-img"
                  />
                </div>
                <div className="client-info">
                  <h3>Tiana Bergson</h3>
                  <span>Tony Pryce</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content">
                <div className="client-img yellow">
                  <img src={ClientImg} className="img-fluid" />
                </div>
                <div className="client-comment">
                  <img src={Images.QuoteLeft} className="img-fluid left-img" />
                  <p>
                    This is one of the best freelancer's I have worked with. Her
                    work delivery is perfect. I will definitely use her again.
                    She's a good listener and pays attention to details. She is
                    definitely someone you need on your team. Good work, great
                    communicator. Will definitely use her again
                  </p>
                  <img
                    src={Images.QuoteRight}
                    className="img-fluid right-img"
                  />
                </div>
                <div className="client-info">
                  <h3>Tiana Bergson</h3>
                  <span>Tony Pryce</span>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}
        </TestomonialSlider>
      </Container>
    </TestomonialWrapper>
  )
}
export default ClientTestomonial
