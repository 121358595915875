import React from "react";
import BannerTitle from "../../comman/BannerTitle";
import { BannerLeftCon } from "../../styles/Hirepages.style";

export const AppDevelopmentLeft = ({ isSection }) => {
  return (
    <>
      {isSection == true ? (
        <BannerLeftCon className="p-0">
          <BannerTitle
            IsSectionTitle
            isSmallText
            MainTitle={<h2>Why Choose Tecocraft <br/> For Your iOS App <br/> Development</h2>}
          ></BannerTitle>
          <p>
            Today, iOS is the second highest-used mobile operating system in the
            market. Suppose you have developed an android app but not launched
            the iOS app. In that case, that means you are already losing the
            potential user segment in a highly competitive marketplace. We have
            experienced iOS app developers in India with rich industry
            experience and expertise who analyze your business requirements and
            create a perfect product for you. We build functionality-rich mobile
            apps that increase user engagement and help you build authority in
            the market and grow your business.
          </p>
          <p>
            Being a pre-eminent iOS app development company in India, we
            understand the mandatory steps and standards to deliver
            user-friendly iPhone apps. With over a decade of experience in the
            iOS app industry, we have a world-class portfolio that includes
            almost all categories of on-demand and fully customized apps from
            health and education to entertainment, retail, & e-commerce. We
            employ the latest technology and tools to build next-gen
            applications for your business, which help us manage high-quality
            services with complete transparency.
          </p>
        </BannerLeftCon>
      ) : (
        <BannerLeftCon className="p-0">
          <BannerTitle
            IsSectionTitle
            isSmallText
            MainTitle={
              <h2>How iOS App Development <br/> Services Are Beneficial <br/> To Business?</h2>
            }
          ></BannerTitle>
          <p>
            The growing market of iOS app development is signifying the
            importance of iOS apps in any business. Let us have a glimpse of how
            iOS app development is going to brighten the future:
          </p>
        </BannerLeftCon>
      )}
    </>
  );
};
export default AppDevelopmentLeft;
