import React from 'react'
import { SectionTitle } from '../styles/globalStyles'

export const TitleSection = ({title,content}) => {
  return (
    <SectionTitle>
        <h3>{title}</h3>
        <p>{content}</p>
    </SectionTitle>
  )
}
export default TitleSection