import styled from 'styled-components'
import Images from '../assets/Images'
import MainButton from '../comman/Button'

export const BannerleftWrapper = styled.div`
  position: relative;
  padding-bottom: 100px;
  &::before {
    content: '';
    background: url(${Images.RedShape}) no-repeat;
    width: 122px;
    height: 210px;
    position: absolute;
    left: 0;
    bottom: 170px;
    background-size: 100%;
  }
  .left-shape-images {
    position: absolute;
    width: 58%;
    z-index: -1;
  }

  @media (max-width: 1440px) {
    padding-bottom: 50px;
    &::before {
      width: 80px;
      height: 150px;
      bottom: 0;
    }
  }
  @media (max-width: 991px) {
    padding-bottom: 30px;
    &::before {
      content: '';
      display: none;
    }
    .banner_li {
      &:first-child {
        order: 2;
        padding-top: 30px;
      }
    }
  }
  @media (max-width: 767px) {
    .left-shape-images {
      display: none;
    }
  }
`
export const BannerWrapperInside = styled.div``
export const BannerImage = styled.div`
  position: absolute;
  right: 0;
  width: 45%;
  top: 0;
  &.banner-small {
    width: 40%;
  }
  @media (max-width: 991px) {
    position: unset;
    width: 100%;
    &.banner-small {
      width: 100%;
    }
    img {
      max-width: 65%;
      margin-left: auto;
      display: block;
    }
  }
  @media (max-width: 767px) {
    img {
      max-width: 100%;
      padding-left: 12px;
    }
  }
`

export const BannerLeftCon = styled.div`
  padding: 250px 0 150px;

  &.blog-content {
    padding-top: 0;
  }
  p {
    margin: 10px 0;
  }
  ul {
    margin: 20px 0;
    li {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      font-family: 'SeguiSemibold';
      margin-bottom: 0;
      color: #71788a;
      padding-left: 15px;
      position: relative;
      &::before {
        content: '';
        width: 7px;
        height: 7px;
        background-color: #71788a;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 13px;
      }
    }
  }

  @media (max-width: 1440px) {
    padding-top: 190px;
  }
  @media (max-width: 1199px) {
    padding-top: 150px;
  }
  @media (max-width: 991px) {
    margin: auto;
    padding: 0 15px;
    max-width: 720px;
  }
  @media (max-width: 767px) {
    max-width: 540px;
    padding: 0 15px !important;
    ul {
      margin: 15px 0;
      li {
        line-height: 1.5;
        font-size: 14px;
        &::before {
          top: 9px;
        }
      }
    }
  }
  @media (max-width: 576px) {
  }
`

export const SectionTitleWrp = styled.div`
  p {
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    p {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 767px) {
    max-width: 100%;
    h2 {
      max-width: 100%;
      br {
        display: none;
      }
    }
  }
`

export const BlogImages = styled.div`
    width: 600px;
  .banner-img {
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    img {
      max-width: 100%;
    }
  }
`

export const BlogWrapper = styled.div`
padding: 80px 0;
  .banner-image {
    padding: 0 0 0 0;
    background: url(${Images.HomeBannerBg}) no-repeat;
    background-size: 100%;
    height: auto;
  }
  
  .banner_li {
    &.blog_images {
      padding-right: 100px;
    }
  }
  @media (max-width: 1199px) {
    padding: 0 0 0;
  }
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
    .banner_li {
      &.blog_images {
        padding-right: 15px;
      }
    }
  }
`
export const ExportWrapper = styled.div`
  background: #373737;
  padding: 73px 0 102px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
  &.service_export_wrapper {
    padding: 73px 0;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    height: 100%;
    width: 160px;
  }
  &::after {
    content: '';
    background: url(${Images.CornerImage}) no-repeat;
    width: 111px;
    height: 102px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-size: 100%;
    z-index: -1;
  }
  @media (max-width: 1600px) {
    padding: 30px 0 50px;
    margin-bottom: 80px;
  }

  @media (max-width: 1280px) {
    img {
      top: auto;
      height: auto;
      width: 9%;
    }
    &::after {
      width: 91px;
      height: 75px;
    }
  }
  @media (max-width: 991px) {
    margin-bottom: 50px;
    &.service_export_wrapper {
      padding: 50px 0;
    }
  }
  @media (max-width: 575px) {
    margin-bottom: 30px;
    &.service_export_wrapper {
      padding: 30px 0;
    }
  }
`
export const LeftText = styled.div`
  max-width: 70%;
  position: relative;
  h2 {
    margin-bottom: 10px;
    text-align: left;
    padding-left: 15px;
    border-left: 3px solid #dd3a31;
    font-size: 30px;
    font-family: 'SeguiSemibold';
    color: #fff;
  }
  p {
    color: #d4d4d5;
    text-align: left;
  }
  button {
    margin-left: auto;
  }
  @media (max-width: 991px) {
    text-align: center;
    max-width: 100%;
    h2 {
      text-align: center;
      padding-left: 0;
      border-left: none;
      font-size: 22px;
    }
    p {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 575px) {
    h2 {
      font-size: 18px;
    }
  }
`
export const WrapperContent = styled.div`
  justify-content: space-between;
  &.onesection {
    text-align: center;
    ${LeftText} {
      max-width: 100%;
      text-align: center;
      h2 {
        border-left: 0;
        padding: 0;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
  button {
    margin-left: auto;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const HireWrapper = styled.div`
  position: relative;
  ${BannerImage} {
    position: unset;
    width: 100%;
  }
  &::before {
    content: '';
    background: url(${Images.ServiceBg}) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100%;
    z-index: -1;
  }
  @media (max-width: 991px) {
    .banner_li:first-child {
      order: 2;
      margin: 30px auto;
      max-width: 720px;
      padding: 0 15px;
    }
  }
  @media (max-width: 767px) {
    .banner_li:first-child {
      max-width: 540px;
    }
  }
`

export const MainCardWrp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  position: relative;
  margin-right: 23px;
  .hire_block_desc {
    height: auto !important;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    margin-left: 60px;
    padding: 20px 24px 20px 63px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 3%), 0 -2px 48px rgb(0 0 0 / 4%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    margin-bottom: 55px;
    margin-right: 0;
  }
  @media (max-width: 575px) {
    margin-bottom: 30px;
    .hire_block_desc {
      margin-left: 50px;
      padding: 20px 16px 20px 34px;
      border-radius: 28px;
    }
  }
`

export const CardIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 110px;
  height: 110px;
  margin: auto;
  svg {
    width: 100%;
    height: 100%;
    box-shadow: 0 27px 35px rgb(0 0 0 / 7%), 0 3px 25px rgb(0 0 0 / 15%);
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 575px) {
    width: 70px;
    height: 70px;
  }
`
export const ServiceBlock = styled.div`
  margin: 100px 0 0;
  @media (max-width: 991px) {
    margin: 50px 0 0;
  }
  @media (max-width: 767px) {
    margin: 20px 0 0;
  }
`

/*Hire Flow section css */
export const HireFlowCon = styled.div`
  padding: 100px 0;
  h3 {
    padding-left: 20px;
    border-left: 3px solid #dd3a31;
    margin-bottom: 30px;
    display: block;
    font-family: 'PoppinsSemiBold';
    font-size: 35px;
  }
  @media (max-width: 1600px) {
    padding: 30px 0 80px;
    h3 {
      font-size: 28px;
    }
  }
  @media (max-width: 991px) {
    padding: 30px 0 50px;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
    h3 {
      font-size: 20px;
    }
  }
  @media (max-width: 575px) {
    h3 {
      font-size: 17px;
    }
  }
`
export const FlowImg = styled.div`
  margin-top: 70px;
  img {
    max-width: 80%;
    margin: auto;
    display: block;
  }
  @media (max-width: 1600px) {
    margin-top: 50px;
  }
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    img {
      max-width: 100%;
    }
  }
`
export const HireBlockInside = styled.div``

/*tak to expoer section css */
export const TalkToExport = styled.div`
  background: url(${Images.TalkToExport}) no-repeat;
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 240px;
  margin-bottom: 100px;
  position: relative;
  @media (max-width: 1600px) {
    margin-top: 130px;
    margin-bottom: 100px;
  }
  @media (max-width: 1440px) {
    margin-top: 180px;
    margin-bottom: 80px;
  }
  @media (max-width: 991px) {
    margin-top: 165px;
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    margin-top: 180px;
  }
  @media (max-width: 575px) {
    margin-bottom: 30px;
    padding: 70px 0;
  }
  @media (max-width: 480px) {
    margin-top: 200px;
  }
  @media (max-width: 380px) {
    margin-top: 170px;
  }
`

export const ExportTitle = styled.div`
  background: #fff;
  padding: 50px;
  border-radius: 50px;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 0 15px 30px rgb(0 0 0 / 16%), 0 3px 25px rgb(0 0 0 / 7%);
  max-width: 1420px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::before,
  &::after {
    display: block;
    position: absolute;
    -webkit-animation: zoomeffect 2s infinite;
    -moz-animation: zoomeffect 2s infinite;
    animation: zoomeffect 2s infinite;
    width: 141px;
    height: 141px;
    background-size: 100% !important;
  }
  &::before {
    content: '';
    background: url(${Images.DoteShpeLeft}) no-repeat;
    top: -45px;
    left: -45px;
  }
  &::after {
    content: '';
    background: url(${Images.DoteShpeRight}) no-repeat;
    bottom: -45px;
    right: -45px;
  }
  @-webkit-keyframes zoomeffect {
    0% {
      background-position: center;
      transform: scale(0.9, 0.9);
    }
    50% {
      background-position: center;
      transform: scale(0.7, 0.7);
    }
    100% {
      background-position: center;
      transform: scale(0.9, 0.9);
    }
  }

  @keyframes zoomeffect {
    0% {
      background-position: center;
      transform: scale(0.9, 0.9);
    }
    50% {
      background-position: center;
      transform: scale(0.7, 0.7);
    }
    100% {
      background-position: center;
      transform: scale(0.9, 0.9);
    }
  }
  .title {
    max-width: 75%;
  }
  @media (max-width: 1750px) {
    max-width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1600px) {
    padding: 38px;
  }
  @media (max-width: 991px) {
    padding: 25px;
    flex-wrap: wrap;
    justify-content: center;
    &::before,
    &::after {
      width: 90px;
      height: 90px;
      background-size: 100% !important;
    }
    &::after {
      right: -23px;
    }
    &::before {
      left: -28px;
    }
    .title {
      max-width: 100%;
      margin-bottom: 30px;
      text-align: center;
      h2 {
        margin-bottom: 10px;
      }
      em {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    border-radius: 34px;
  }
  @media (max-width: 575px) {
    &::before,
    &::after {
      display: none;
    }
  }
`

export const KeyBenifitWrapper = styled.div`
  height: 100%;
  .amfeatures_block {
    height: 100%;
    padding: 25px 0;
    margin: 0;
    padding: 25px 0;
    border-bottom: 2px dashed #c2c2c2;
    h4 {
      padding-left: 30px;
    }
  }
  .amfeatures_title {
    display: flex;
    align-items: center;
  }
  p {
    color: #71788a;
    margin-top: 20px;
  }
  @media (max-width: 1600px) {
    .amfeatures_block {
      h4 {
        padding-left: 15px;
      }
    }
  }
`
export const IconsDiv = styled.div`
  svg {
    width: 80px;
    height: 80px;
    box-shadow: 0 2px 45px rgb(0 0 0 / 17%), 0 -1px 19px rgb(0 0 0 / 2%);
    border-radius: 50%;
  }
  @media (max-width: 575px) {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`
export const keySectionDiv = styled.div`
  padding: 0 0 100px;
`
export const KeyBenifitMain = styled.div`
  padding: 0 0 100px;
  position: relative;
  &::after {
    content: '';
    background: url(${Images.RightShape}) no-repeat;
    width: 116px;
    height: 240px;
    background-size: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media (max-width: 1440px) {
    padding: 0 0 80px;
  }
  @media (max-width: 991px) {
    padding: 0 0 50px;
    &::after {
      width: 66px;
      height: 124px;
    }
  }
  @media (max-width: 767px) {
    &::after {
      display: none;
    }
  }
  @media (max-width: 767px) {
    padding: 0 0 30px;
  }
`

/*services page css */
export const ServiceWrapper = styled.div`
  padding-top: 118px;
  position: relative;
  &::before {
    content: '';
    background: url(${Images.HireServiceBg}) no-repeat;
    width: 100%;
    height: 100%;
    background-size: 100%;
    position: absolute;
    top: 37px;
    z-index: -1;
  }
  .service_li {
    ${SectionTitleWrp} {
      padding-top: 100px;
    }
  }
  @media (max-width: 991px) {
    padding-top: 0;
    .service_li {
      ${SectionTitleWrp} {
        padding-top: 30px;
      }
      &.last {
        max-width: 720px;
        margin: auto;
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
    .service_li {
      &.last {
        max-width: 520px;
      }
    }
  }
`
export const ServiceImage = styled.div`
  padding-right: 50px;
`
export const ServiceCradLi = styled.div`
  padding: 80px 0;
  @media (max-width: 991px) {
    padding: 30px 0;
  }
`
export const ServiceCardWrapper = styled.div`
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 3px 50px rgb(168 168 168 / 16%);
  display: flex;
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 43px;
  display: flex;
  align-items: center;
  h4 {
    color: #222222;
    font-size: 16px;
    font-weight: 800;
  }
  svg {
    width: 84px;
    height: 84px;
    margin: 0 19px 0 0;
    box-shadow: 0px 3px 50px rgb(0 0 0 / 8%);
    border-radius: 50%;
  }
  @media (max-width: 991px) {
    svg {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 25px;
    border-radius: 20px;
    h4 {
      font-size: 14px;
    }
  }
  @media (max-width: 380px) {
    h4 {
      font-size: 12px;
    }
  }
`

/*Development section css*/

export const DevlopmentWrapper = styled.div`
  padding-bottom: 80px;
  @media (max-width: 575px) {
    padding-bottom: 40px;
  }
`
export const DevelopmentLeftCon = styled.div`
  @media (max-width: 991px) {
    max-width: 720px;
    margin: auto;
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    max-width: 540px;
  }
`
export const DevelopmentCon = styled.div`
  margin-top: 80px;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`
export const DevelopmentList = styled.div`
  margin: 0 0 30px;
  svg {
    box-shadow: 0 0px 27px rgb(0 0 0 / 22%), 0 3px 25px rgb(0 0 0 / 2%);
    border-radius: 50%;
    float: left;
    margin-right: 20px;
    width: 100px;
    height: 100px;
  }
  .devlopmet-con {
    h5 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #222222;
      font-family: 'PoppinsBold';
    }
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @media (max-width: 991px) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    svg {
      margin-right: 0;
    }
    .devlopmet-con {
      margin-top: 20px;
      text-align: center;
    }
  }
`
export const DevelopmentImage = styled.div`
  padding-left: 100px;
  img {
    margin-left: auto;
    display: block;
  }
`
/*faq section css */
export const AccordationDiv = styled.div`
  margin-top: 30px;
`
export const FaQContent = styled.div`
  padding-bottom: 100px;
  h2 {
    font-size: 22px;
    cursor: pointer;
    line-height: 1.5;
  }
  .accordion-item {
    background-color: #fff;
    border-radius: 25px !important;
    padding: 30px 30px;
    margin-bottom: 34px;
    border: 0;
    box-shadow: 0px 3px 50px rgb(168 168 168 / 16%);
  }
  .accordion-button {
    padding: 0;
    font-size: 21px;
    transition: all 0.5s ease;
    padding-right: 50px;
    span {
      color: #dd3a31;
      font-size: 21px;
      font-family: 'PoppinsBold';
      margin-right: 5px;
    }
    &:focus {
      box-shadow: unset;
    }
    &:not(.collapsed) {
      color: #000;
      background-color: unset;
      border: 0 !important;
      box-shadow: unset;
    }
    &::after {
      background-image: url(${Images.SideArrow});
      width: 38px;
      height: 38px;
      background-size: 100%;
      position: absolute;
      right: 0;
    }
    &:not(.collapsed)::after {
      background-image: url(${Images.BlackDownArrow});
      transform: unset;
    }
  }
  .accordion-collapse {
    /* height: auto !important;
    overflow: unset !important; */
  }
  .accordion-body {
    padding: 10px 0 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    font-family: 'SeguiSemibold';
    margin-bottom: 0;
    color: #71788a;
    max-width: 80%;
    ul {
      li {
        line-height: 30px;
        font-weight: 400;
        margin-bottom: 0;
        color: #71788a;
        padding-left: 16px;
        position: relative;
        font-family: 'SeguiSemibold';
        font-size: 18px;
        &::before {
          content: '';
          width: 7px;
          height: 7px;
          display: block;
          background: #000;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    padding-bottom: 80px;
  }
  @media (max-width: 991px) {
    padding-bottom: 50px;
    .accordion-button {
      font-size: 18px;
    }
  }
  @media (max-width: 575px) {
    padding-bottom: 30px;
    .accordion-body 
    {
      max-width: 100%;
    }
    .accordion-item {
      padding: 20px;
    }
    .accordion-button {
      font-size: 16px;
      &::after {
        width: 28px;
        height: 28px;
      }
    }
  }
`
