import pMinDelay from 'p-min-delay'
import React, { Suspense, useEffect, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AndroidApp from '../pages/AndroidApp'
import IOSServiceApp from '../pages/IOSServiceApp'

import LazyLoad from './LazyLoad'

const Home = LazyLoad(import('../pages/Home.jsx'))

const Loader = lazy(() => pMinDelay(import('../comman/Loader.jsx'), 1))

const NoPage = LazyLoad(import('../pages/NoPage.jsx'))

const Routers = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="android-app" element={<AndroidApp />} />
            <Route path="iOs-service-app" element={<IOSServiceApp/>} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  )
}

export default Routers
