import { ReactComponent as Twitter } from '../assets/Footer/Twitter.svg'
import { ReactComponent as LinkedIN } from '../assets/Footer/LinkedIN.svg'
import { ReactComponent as FaceBook } from '../assets/Footer/FaceBook.svg'
import { ReactComponent as Instagram } from '../assets/Footer/Instagram.svg'
import { ReactComponent as Pinterest } from '../assets/Footer/Pinterest.svg'
import { ReactComponent as Map } from '../assets/Footer/Map.svg'
import { ReactComponent as Email } from '../assets/Footer/Email.svg'
import { ReactComponent as Contact } from '../assets/Footer/Contact.svg'
import { ReactComponent as Skype } from '../assets/Footer/Skype.svg'
import { ReactComponent as Call } from '../assets/Footer/Call.svg'
import { ReactComponent as LocationWhite } from '../assets/Footer/Location-White.svg'
import { ReactComponent as Police } from '../assets/Footer/Police.svg'
import { ReactComponent as KeyIcon1 } from '../assets/Hire_pages_images/Key-Benifit-Icon1.svg'
import { ReactComponent as KeyIcon2 } from '../assets/Hire_pages_images/Key-Benifit-Icon2.svg'
import { ReactComponent as KeyIcon3 } from '../assets/Hire_pages_images/Key-Benifit-Icon3.svg'
import { ReactComponent as KeyIcon4 } from '../assets/Hire_pages_images/Key-Benifit-Icon4.svg'
import { ReactComponent as KeyIcon5 } from '../assets/Hire_pages_images/Key-Benifit-Icon5.svg'
import { ReactComponent as KeyIcon6 } from '../assets/Hire_pages_images/Key-Benifit-Icon6.svg'
import { ReactComponent as KeyIcon7 } from '../assets/Hire_pages_images/Key-Benifit-Icon7.svg'
import { ReactComponent as KeyIcon8 } from '../assets/Hire_pages_images/Key-Benifit-Icon8.svg'
import { ReactComponent as Arrow } from '../assets/Arrow.svg'
import { ReactComponent as ServiceIcon } from '../assets/Hire_pages_images/Service-Icon1.svg'
import { ReactComponent as ServiceIcon2 } from '../assets/Hire_pages_images/Service-Icon2.svg'
import { ReactComponent as ServiceIcon3 } from '../assets/Hire_pages_images/Service-Icon3.svg'
import { ReactComponent as ServiceIcon4 } from '../assets/Hire_pages_images/Service-Icon4.svg'
import { ReactComponent as ServiceIcon5 } from '../assets/Hire_pages_images/Service-Icon5.svg'
import { ReactComponent as ServiceIcon6 } from '../assets/Hire_pages_images/Service-Icon6.svg'
import { ReactComponent as DevelopmentIcon } from '../assets/Hire_pages_images/Develop-Icon1.svg'
import { ReactComponent as DevelopmentIcon2 } from '../assets/Hire_pages_images/Develop-Icon2.svg'
import { ReactComponent as DevelopmentIcon3 } from '../assets/Hire_pages_images/Develop-Icon3.svg'
import { ReactComponent as DevelopmentIcon4 } from '../assets/Hire_pages_images/Develop-Icon4.svg'
import { ReactComponent as DevelopmentIcon5 } from '../assets/Hire_pages_images/Develop-Icon5.svg'
import { ReactComponent as DevelopmentIcon6 } from '../assets/Hire_pages_images/Develop-Icon6.svg'
import { ReactComponent as HireIcon1 } from '../assets/Hire_pages_images/Hire-App-Icon1.svg'
import { ReactComponent as HireIcon2 } from '../assets/Hire_pages_images/Hire-App-Icon2.svg'
import { ReactComponent as HireIcon3 } from '../assets/Hire_pages_images/Hire-App-Icon3.svg'
import { ReactComponent as HireIcon4 } from '../assets/Hire_pages_images/Hire-App-Icon4.svg'
import { ReactComponent as HireIcon5 } from '../assets/Hire_pages_images/Hire-App-Icon5.svg'
import { ReactComponent as HireIcon6 } from '../assets/Hire_pages_images/Hire-App-Icon6.svg'
import { ReactComponent as RedIcon } from '../assets/Service_pages_images/Red.svg'
import { ReactComponent as BlueIcon } from '../assets/Service_pages_images/Blue.svg'
import { ReactComponent as GreenIcon } from '../assets/Service_pages_images/Green.svg'
import { ReactComponent as YellowIcon } from '../assets/Service_pages_images/Yellow.svg'
import { ReactComponent as WorkflowIcon1 } from '../assets/Home/Workflow-Icon1.svg'
import { ReactComponent as WorkflowIcon2 } from '../assets/Home/Workflow-Icon2.svg'
import { ReactComponent as WorkflowIcon3 } from '../assets/Home/Workflow-Icon3.svg'
import { ReactComponent as WorkflowIcon4 } from '../assets/Home/Workflow-Icon4.svg'
import { ReactComponent as IndustriesIcon } from '../assets/Service_pages_images/Industries-Icon.svg'
import { ReactComponent as IndustriesIcon1 } from '../assets/Service_pages_images/Industries-Icon3.svg'
import { ReactComponent as IndustriesIcon2 } from '../assets/Service_pages_images/Industries-Icon4.svg'
import TraveLImg from '../assets/Home/Industry-Travel-Img.png'
import RealEstateImg from '../assets/Home/Industry-Real-Estate-Img.png'
import MedicalImg from '../assets/Home/Industry-Medical-Img.png'
import EducationLearning from '../assets/Home/Industry-Education-Img.png'
import Fitness from '../assets/Home/Industry-Fitness-Img.png'
import MedicaImage from '../assets/Home/Industry-Media-Img.png'
import EnergyUtilities from '../assets/Home/Industry-Energy-Img.png'
import SportsGames from '../assets/Home/Industry-Spots-Img.png'
import BankingFinance from '../assets/Home/Industry-Banking-Img.png'
import SocialNetworking from '../assets/Home/Industry-Social-Img.png'
import LogisticDistribution from '../assets/Home/Industry-Logistics-Img.png'
import ECommerce from '../assets/Home/Industry-E-Commerce-Img.png'

import AndroidBlack from '../assets/Header/Hire-Android-App-Developer-Black.png'
import AndroidRed from '../assets/Header/Hire-Android-App-Developer-Red.png'

import IosBlack from '../assets/Header/Hire-IOS-App-Developer-Black.png'
import IosRed from '../assets/Header/Hire-IOS-App-Developer-Red.png'

import SwiftBlack from '../assets/Header/Hire-Swift-App-Developer-Black.png'
import SwiftRed from '../assets/Header/Hire-Swift-App-Developer-Red.png'

import FlutterBlack from '../assets/Header/Hire-Flutter-App-Developer-Black.png'
import FlutterRed from '../assets/Header/Hire-Flutter-App-Developer-Red.png'

import ReactNativeBlack from '../assets/Header/Hire-React-Native-App-Developer-Black.png'
import ReactNativeRed from '../assets/Header/Hire-React-Native-App-Developer-Red.png'

import WordPressBlack from '../assets/Header/Hire-WordPress-Developer-Black.png'
import WordPressRed from '../assets/Header/Hire-WordPress-Developer-Red.png'

import WooCommerceBlack from '../assets/Header/Hire-WooCommerce-Developer-Black.png'
import WooCommerceRed from '../assets/Header/Hire-WooCommerce-Developer-Red.png'

import MegentoBlack from '../assets/Header/Hire-Magento-Developer-Black.png'
import MegentoRed from '../assets/Header/Hire-Magento-Developer-Red.png'

import ShopifyBlack from '../assets/Header/Hire-Shopify-Developer-Black.png'
import ShopifyRed from '../assets/Header/Hire-Shopify-Developer-Red.png'

import PhpBlack from '../assets/Header/Hire-PHP-Developer-Black.png'
import PhpRed from '../assets/Header/Hire-PHP-Developer-Red.png'

import PythonBlack from '../assets/Header/Hire-Python-Developer-Black.png'
import PythonRed from '../assets/Header/Hire-Python-Developer-Red.png'

import LaravelBlack from '../assets/Header/Hire-Laravel-Developer-Black.png'
import LaravelRed from '../assets/Header/Hire-Laravel-Developer-Red.png'

import UXUIBlack from '../assets/Header/Hire-UXUI-Developer-Black.png'
import UXUIRed from '../assets/Header/Hire-UXUI-Developer-Red.png'

import SoftwareTesterBlack from '../assets/Header/Hire-Software-Tester-Black.png'
import SoftwareTesterRed from '../assets/Header/Hire-Software-Tester-Red.png'

import DevOpsBlack from '../assets/Header/Hire-DevOps-Engineer-Black.png'
import DevOpsRed from '../assets/Header/Hire-DevOps-Engineer-Red.png'

import Logo1 from '../assets/Home/Logo1.png'
import Logo2 from '../assets/Home/Logo2.png'
import Logo3 from '../assets/Home/Logo3.png'
import Logo4 from '../assets/Home/Logo4.png'
import Logo5 from '../assets/Home/Logo5.png'
import Logo6 from '../assets/Home/Logo6.png'

import Icon1 from '../assets/Home/Icon1.png'
import Icon2 from '../assets/Home/Icon2.png'
import Icon3 from '../assets/Home/Icon3.png'
import Icon4 from '../assets/Home/Icon4.png'
import Icon5 from '../assets/Home/Icon5.png'
import Icon6 from '../assets/Home/Icon6.png'

import SliderImage from '../assets/Service_pages_images/Key-Device.png'

/*Development process images */
import ProcessImage from '../assets/Service_pages_images/Service-Num1.png'
import ProcessImage2 from '../assets/Service_pages_images/Service-Num2.png'
import ProcessImage3 from '../assets/Service_pages_images/Service-Num3.png'
import ProcessImage4 from '../assets/Service_pages_images/Service-Num4.png'
import ProcessImage5 from '../assets/Service_pages_images/Service-Num5.png'
import ProcessImage6 from '../assets/Service_pages_images/Service-Num6.png'
import CouldImage from '../assets/Service_pages_images/Service-Cloud.png'

/*Technology Slider Image */
import TechnologyImage from '../assets/Service_pages_images/Service-Tech1.png'
import TechnologyImage1 from '../assets/Service_pages_images/Service-Tech2.png'
import TechnologyImage2 from '../assets/Service_pages_images/Service-Tech3.png'
import TechnologyImage3 from '../assets/Service_pages_images/Service-Tech4.png'
import TechnologyImage4 from '../assets/Service_pages_images/Service-Tech5.png'

//Home Slider Certification
import Certification1 from '../assets/Home/Certification1.png'
import Certification2 from '../assets/Home/Certification2.png'
import Certification3 from '../assets/Home/Certification3.png'

// ICO LOGO IMAGE
import IcoLogoBlue from '../assets/Home/Ico-Logo-Blue.png'
import ClientImage from '../assets/Service_pages_images/Client4.png'

import ProjectImage1 from '../assets/Home/Project-Img1.png'
import ProjectImage2 from '../assets/Home/Project-Img2.png'
import ProjectImage3 from '../assets/Home/Project-Img3.png'
import WebImg from '../assets/Home/Web-Img.png'
import AndroidSmallImage from '../assets/Home/Android-Img.png'
import AppleSmallImage from '../assets/Home/Apple-Img.png'

export const MenuData = [
  {
    name: 'Services',
    path: '/',
    details: false,
    menuDetail: true,
    isLogo: false,
    isSiteInfo: false,
    isSolution: false,

    InnnerMenu: [
      {
        title: 'Mobile App Development ',
        isColor: false,
        isMenu: false,
        InnerItem: [
          {
            name: 'IOS App Development',
            path: '/iOs-service-app',
          },
          {
            name: 'Android App Development',
            path: '/',
          },
          {
            name: 'Swift App Development',
            path: '/',
          },
          {
            name: 'Ipad App Development',
            path: '/',
          },
          {
            name: 'Smart Watch App Development',
            path: '/',
          },
          {
            name: 'Flutter App Development',
            path: '/',
          },
        ],
      },
      {
        title: 'Web/CMS & Ecommerce ',
        isMenu: false,
        InnerItem: [
          {
            name: 'PHP Development',
            path: '/',
          },
          {
            name: 'Laravel Development',
            path: '/',
          },
          {
            name: 'AngularJs Development',
            path: '/',
          },
          {
            name: 'Wordpress Development',
            path: '/',
          },
          {
            name: 'WooCommerce Development',
            path: '/',
          },
          {
            name: 'Magento Development',
            path: '/',
          },
          {
            name: 'Shopify Development',
            path: '/',
          },
          {
            name: 'Python Development',
            path: '/',
          },
          {
            name: 'Net Development',
            path: '/',
          },
        ],
      },
      {
        title: 'Digital Marketing',
        isMenu: true,
        isMenuUxUI: true,
        InnerItem: [
          {
            name: 'SEO (Search Engine Optimization)',
            path: '/',
          },
          {
            name: 'SMM (Social Media Marketing)',
            path: '/',
          },
          {
            name: 'SEM (Search Engine Marketing)',
            path: '/',
          },
          {
            name: 'App Store Optimization',
            path: '/',
          },
        ],
      },
      {
        title: 'Real Time Solution',
        isMenu: true,
        InnerItem: [
          {
            name: 'Chatting Apps',
            path: '/',
          },
          {
            name: 'Real Time Mobile App',
            path: '/',
          },
          {
            name: 'Real Time Web AppsReal-Time Web Apps',
            path: '/',
          },
          {
            name: 'Real Time Dashboard',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    name: 'Hire Resources',
    path: '/',
    details: true,
    menuDetail: false,
    isLogo: true,
    isSiteInfo: false,
    isSolution: false,
    InnnerMenu: [
      {
        title: false,
        isColor: false,
        InnerItem: [
          {
            name: 'Hire Android App Developer',
            path: '/android-app',
            image1: AndroidBlack,
            image2: AndroidRed,
          },
          {
            name: 'Hire IOS App Developer',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Hire Swift App Developer',
            path: '/',
            image1: SwiftBlack,
            image2: SwiftRed,
          },
          {
            name: 'Hire Flutter App Developer',
            path: '/',
            image1: FlutterBlack,
            image2: FlutterRed,
          },
          {
            name: 'Hire React Native App Developer',
            path: '/',
            image1: ReactNativeBlack,
            image2: ReactNativeRed,
          },
        ],
      },
      {
        InnerItem: [
          {
            name: 'Hire WordPress Developer',
            path: '/',
            image1: WordPressBlack,
            image2: WordPressRed,
          },
          {
            name: 'Hire WooCommerce Developer',
            path: '/',
            image1: WooCommerceBlack,
            image2: WooCommerceRed,
          },
          {
            name: 'Hire Magento Developer',
            path: '/',
            image1: MegentoBlack,
            image2: MegentoRed,
          },
          {
            name: 'Hire Shopify Developer',
            path: '/',
            image1: ShopifyBlack,
            image2: ShopifyRed,
          },
          {
            name: ' Hire PHP Developer',
            path: '/',
            image1: PhpBlack,
            image2: PhpRed,
          },
        ],
      },
      {
        InnerItem: [
          {
            name: 'Hire Python Developer',
            path: '/',
            image1: PythonBlack,
            image2: PythonRed,
          },
          {
            name: 'Hire Laravel Developer',
            path: '/',
            image1: LaravelBlack,
            image2: LaravelRed,
          },
          {
            name: 'Hire UX/UI Developer',
            path: '/',
            image1: UXUIBlack,
            image2: UXUIRed,
          },
          {
            name: 'Hire Software Tester',
            path: '/',
            image1: SoftwareTesterBlack,
            image2: SoftwareTesterRed,
          },
          {
            name: 'Hire DevOps Engineer',
            path: '/',
            image1: DevOpsBlack,
            image2: DevOpsRed,
          },
        ],
      },
    ],
  },
  {
    name: 'Industries',
    path: '/',
    details: true,
    menuDetail: false,
    isLogo: true,
    isSiteInfo: true,
    isSolution: false,
    InnnerMenu: [
      {
        title: false,
        isColor: false,
        isLogo: true,
        InnerItem: [
          {
            name: 'Travel & Hospitality',
            path: '/',
            image1: AndroidBlack,
            image2: AndroidRed,
          },
          {
            name: 'Education & Learning',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Social Networking',
            path: '/',
            image1: SwiftBlack,
            image2: SwiftRed,
          },
          {
            name: 'Energy and Utilities',
            path: '/',
            image1: FlutterBlack,
            image2: FlutterRed,
          },
        ],
      },
      {
        InnerItem: [
          {
            name: 'Real estate',
            path: '/',
            image1: WordPressBlack,
            image2: WordPressRed,
          },
          {
            name: 'Fitness & Wellness',
            path: '/',
            image1: WooCommerceBlack,
            image2: WooCommerceRed,
          },
          {
            name: 'E-Commerce',
            path: '/',
            image1: MegentoBlack,
            image2: MegentoRed,
          },
          {
            name: 'Sports & Gamers',
            path: '/',
            image1: ShopifyBlack,
            image2: ShopifyRed,
          },
        ],
      },
      {
        InnerItem: [
          {
            name: 'Medical & Health Care',
            path: '/',
            image1: PythonBlack,
            image2: PythonRed,
          },
          {
            name: 'Banking & Finance',
            path: '/',
            image1: LaravelBlack,
            image2: LaravelRed,
          },
          {
            name: 'Logistics & Distributions',
            path: '/',
            image1: UXUIBlack,
            image2: UXUIRed,
          },
          {
            name: 'Media & Entertainment Industry',
            path: '/',
            image1: SoftwareTesterBlack,
            image2: SoftwareTesterRed,
          },
        ],
      },
    ],
  },
  {
    name: 'Solution',
    path: '/',
    details: true,
    menuDetail: false,
    isLogo: true,
    isSiteInfo: false,
    isSolution: true,
    InnnerMenu: [
      {
        title: false,
        isColor: false,
        InnerItem: [
          {
            name: 'Healthcare Solutions',
            path: '/',
            image1: AndroidBlack,
            image2: AndroidRed,
          },
          {
            name: 'Hotel Booking App Development',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Education Management App Development',
            path: '/',
            image1: SwiftBlack,
            image2: SwiftRed,
          },
          {
            name: 'Sports Betting App Development',
            path: '/',
            image1: FlutterBlack,
            image2: FlutterRed,
          },
        ],
      },
      {
        InnerItem: [
          {
            name: 'Food Ordering App Development',
            path: '/',
            image1: WordPressBlack,
            image2: WordPressRed,
          },
          {
            name: 'E-Commerce App Development',
            path: '/',
            image1: WooCommerceBlack,
            image2: WooCommerceRed,
          },
          {
            name: 'Material Management Software Development',
            path: '/',
            image1: MegentoBlack,
            image2: MegentoRed,
          },
        ],
      },
    ],
  },
  {
    name: 'Resources',
    path: '/',
    details: true,
    menuDetail: false,
    isLogo: false,
    isSiteInfo: false,
    isSolution: false,
    InnnerMenu: [
      {
        title: false,
        isColor: false,
        InnerItem: [
          {
            name: 'Blog',
            path: '/',
            image1: AndroidBlack,
            image2: AndroidRed,
          },
          {
            name: 'Case Studies',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
        ],
      },
    ],
  },
  {
    name: 'Company',
    path: '/',
    details: true,
    menuDetail: false,
    isLogo: false,
    isSiteInfo: false,
    isSolution: false,
    InnnerMenu: [
      {
        title: false,
        isColor: false,
        InnerItem: [
          {
            name: 'About Us',
            path: '/',
            image1: AndroidBlack,
            image2: AndroidRed,
          },
          {
            name: 'Our Work Portfolio',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Testimonial',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Contact Us',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
          {
            name: 'Carees',
            path: '/',
            image1: IosBlack,
            image2: IosRed,
          },
        ],
      },
    ],
  },
]

export const Icons = [
  {
    name: <FaceBook />,
    path: '/',
  },
  {
    name: <Instagram />,
    path: '/',
  },
  {
    name: <LinkedIN />,
    path: '/',
  },
  {
    name: <Twitter />,
    path: '/',
  },
  {
    name: <Pinterest />,
    path: '/',
  },
]

export const Text = [
  {
    name: 'Our Company  ',
    icon: <LocationWhite />,
    title: 'Adajan, Surat, Gujarat',
    path: '/',
  },
  {
    name: 'Email US',
    title: 'info@tecocraft.com',
    path: '/',
    icon: <Email />,
  },
  {
    name: 'Call US  ',
    title: '+44 7833 027277',
    path: '/',
    icon: <Call />,
  },
]

export const Heading = [
  {
    content: 'Top-10 E-Commerce Mobile App Features In 2021',
    path: '/',
    subContent: 'March 12, 2021',
  },
  {
    // title: 'Latest From Our Blog',
    content:
      'Twitter. Facebook May Not Be Able To Operate In India From May 26',
    path: '/',
    subContent: 'March 12, 2021',
  },
  {
    content: ' Top PHP Frameworks',
    path: '/',
    subContent: 'May 17. 2021',
  },
]

export const Contactdetails = [
  {
    title: 'HR Inquiry',
    content1: 'hr@tecocraft.com',
    content2: '91 99790 09015',
    path: '/',
    icon: <Email />,
    icon2: <Contact />,
  },
  {
    title: 'Our Products ',
    content1: 'Guard Tour System',
    content2: 'Live Location Tracker',
    path: '/',
    icon: <Police />,
    icon2: <Map />,
  },
]

export const SocialMedia = [
  {
    media: 'Email',
    contact: 'sales@tecocraft.com',
    path: '/',
    icon: <Email />,
  },
  {
    media: 'Skype',
    contact: 'live:tecocraft.infusion',
    path: '/',
    icon: <Skype />,
  },
  {
    media: 'Phone',
    contact: '+91 99799 84015',
    path: '/',
    icon: <Contact />,
  },
]

export const CompanyImage = [
  {
    logo: Logo1,
  },
  {
    logo: Logo2,
  },

  {
    logo: Logo3,
  },

  {
    logo: Logo4,
  },

  {
    logo: Logo5,
  },

  {
    logo: Logo6,
  },
]

export const DevelopmentBlockData = [
  {
    title: 'Mobile App',
    content:
      ' We build the highest quality, feature-rich, and top-rated Android/iOS mobile applications to meet your business needs.',
    path: '/',
    image: Icon1,
    arrow: <Arrow />,
  },
  {
    title: 'Web/cms & Ecommerce',
    content:
      'We build the highest quality, feature-rich, and top-rated Android/iOS mobile applications to meet your business needs.',
    path: '/',
    image: Icon2,
    arrow: <Arrow />,
  },
  {
    title: 'Digital Marketing',
    content:
      'Get a fully responsive, eye-catchy, and highly interactive UI/UX design of your website and app to deliver a great user experience.',
    path: '/',
    image: Icon3,
    arrow: <Arrow />,
  },
  {
    title: 'Real-time Solution',
    content:
      'We offer fully customized and value-driven real-time software IT solutions for clients ranging from different industry verticals.',
    path: '/',
    image: Icon4,
    arrow: <Arrow />,
  },
  {
    title: 'Quality Testing',
    content:
      'We perform multi-stage feature testing of your web and apps to ensure high-quality without any bug or technical glitch.',
    path: '/',
    image: Icon5,
    arrow: <Arrow />,
  },
  {
    title: 'UI/UX Design',
    content:
      'Get a fully responsive, eye-catchy, and highly interactive UI/UX design of your website and app to deliver a great user experience.',
    path: '/',
    image: Icon6,
    arrow: <Arrow />,
  },
]

export const KeyBenifitList = [
  {
    img: <KeyIcon1 />,
    Title: '100% Client Satisfaction',
    Content:
      'We have completed 4200+ projects combining small startup mobile applications to enterprise-level solutions.',
  },
  {
    img: <KeyIcon2 />,
    Title: 'Free No Obligation Quote',
    Content:
      'We listen to your product idea and provide a no-cost estimate with brief analysis within 24 working hours.',
  },
  {
    img: <KeyIcon3 />,
    Title: 'Get Results In 48 Hours',
    Content:
      'Our developers adopt technologies very quickly and start producing results within a short time.',
  },
  {
    img: <KeyIcon4 />,
    Title: 'Keep Your Clients Coming Back',
    Content:
      'We design fantastic user experiences that make your clients come back to you for further services.',
  },
  {
    img: <KeyIcon5 />,
    Title: 'Your Local Guy Costs More',
    Content:
      'Our service perfectly balances the competitive pricing and uncompromising quality ensuring you to charge the lowest in our segment.',
  },
  {
    img: <KeyIcon6 />,
    Title: 'Integrity & Transparency',
    Content:
      'We go through the NDA process and make the whole process of development and billing transparent so you can rely on our services and modules of work.',
  },
  {
    img: <KeyIcon7 />,
    Title: 'Trusted & Skilled Android Programmer',
    Content:
      'As per your project requirements, we pair you with the best android programmers team experienced in similar work and suitable for your project.',
  },
  {
    img: <KeyIcon8 />,
    Title: 'Hire A Team Of Your Choice',
    Content:
      'Our service perfectly balances the competitive pricing and uncompromising quality ensuring you to charge the lowest in our segment.',
  },
]

export const MilestoneData = [
  {
    count: 400,
    title: 'Project Launched',
  },
  {
    count: 4,
    title: 'Years of Industry Expertise',
  },
  {
    count: 100,
    title: 'Dedicated Professionals',
  },
  {
    count: 200,
    title: 'Client Served',
  },
]
export const ServiceData = [
  {
    Icon: <ServiceIcon />,
    content: 'Custom Android App Development',
  },
  {
    Icon: <ServiceIcon2 />,
    content: 'Android Game Development',
  },
  {
    Icon: <ServiceIcon3 />,
    content: 'Android App Upgradation & Migration',
  },
  {
    Icon: <ServiceIcon4 />,
    content: 'Android M-commerce Applications',
  },
  {
    Icon: <ServiceIcon5 />,
    content: 'App Support & Maintenance',
  },
  {
    Icon: <ServiceIcon6 />,
    content: 'QA / Testing',
  },
]
export const DevelopmentData = [
  {
    Image: <DevelopmentIcon />,
    Title: 'Creativity and innovation',
    Content:
      'Our team combination of UI/UX designers and developers craft unique and creative product that hold users on their screens with the optimal user experience.',
  },
  {
    Image: <DevelopmentIcon2 />,
    Title: 'Technical Expertise',
    Content:
      'Android developers at Tecocraft have technical proficiency in Java, Kotlin, libraries, Android Studio and so forth.',
  },
  {
    Image: <DevelopmentIcon3 />,
    Title: 'Consultation',
    Content:
      'We provide free consultation clearing your doubts about your product idea that help you launch the unbeatable product on play store.',
  },
  {
    Image: <DevelopmentIcon4 />,
    Title: 'Communication',
    Content:
      'From project discussion to query handling, We will be connected with you all the time via communication channels as per your requirements, either chat, voice or video calls.',
  },
  {
    Image: <DevelopmentIcon5 />,
    Title: 'Certified Developers',
    Content:
      'Our in-house Android developers are highly qualified having 3+ years of experience in app development ready to build top-of-the-funnel features.',
  },
  {
    Image: <DevelopmentIcon6 />,
    Title: 'Security',
    Content:
      "We follow security standards with NDA agreements to protect your product idea and concepts Don't hesitate to share your product details with us, We will ensure you high confidentiality",
  },
]
export const Faqdata = [
  {
    Number: '1.',
    Title: 'Why should I hire an Android app developer from your company?',
    Text:
      'We have a highly talented team of Android app developers with experience of 5+ years, who are powerfully skilled with the knowledge of XMLs, programming skills, SDKs, and Android Studio.',
  },
  {
    Number: '2.',
    Title: 'How are the Android developers of Tecocraft unique from others?',
    Text:
      'At Tecocraft, we pick out the developers through a very detailed procedure, and only the ones with the highest calibre are welcomed. If you think of hiring Android app developers in India, Tecocraft is a perfect choice. You will get to associate with the engineering experts who can comprehend well your technical goals, team dynamics, and business needs and offer you custom-match results.',
  },
  {
    Number: '3.',
    Title:
      'Can I hire a remote Android developer on an hourly or project basis?',
    Text:
      'Yes, you can. We have a flexible hiring model at Tecocraft. If you thoroughly know how to get the task done from the developer, you may choose the hiring model as per your needs. Further, it can be reformed later on if the market arrives.',
  },
  {
    Number: '4.',
    Title:
      'What type of Android app development services are offered by Tecocraft?',
    Text:
      'Our incredible team of Android app developers can offer multiple services such as; Hybrid app development, App migration, IoT development, customized application development, Android Support & maintenance, Android app testing, Native app development, etc.',
  },
  {
    Number: '5.',
    Title:
      'How will I get updates if I hire an Android programmer remotely from ?',
    Text:
      'We offer regular updates about your Android project through eMail, Skype, Call, etc. Our team also shares daily/weekly/monthly progress reports with the client. Further, a dedicated project manager will keep in touch with you throughout the project journey.',
  },
  {
    Number: '6.',
    Title:
      'What is the estimated cost to hire Android app developers in India?',
    Text:
      'There is no certain response to this query. Several factors contribute to anticipating the cost of Android app development, such as; type of app, the experience of Android developers, the platform used, number of pages, variety of functions, design of the app, cost of maintenance, etc.',
  },
  {
    Number: '7.',
    Title: 'Will my Android app development idea be safe with your team?',
    Text:
      'Yes. We sign an NDA (Non-Disclosure Agreement) with the client whenever you hire an Android developer from us. According to this pact, our company can never reveal the app idea of our client.',
  },
  {
    Number: '8.',
    Title: 'Can I replace my Android programmer if not satisfied?',
    Text:
      'We have the best Android app developers.There are minimal chances that you will not be satisfied with our highly proficient team of Android developers. If you hire an Android app developer from us and don’t get the expected services, you may replace the programmer anytime.',
  },
]

export const Hiredata = [
  {
    Icon: <HireIcon1 />,
    Text:
      'Flexible hiring with the option to choose an individual android developer with the interview processs',
  },
  {
    Icon: <HireIcon2 />,
    Text:
      'Proper communication using different channels like Skype, mail, and phone.',
  },
  {
    Icon: <HireIcon3 />,
    Text: 'Established project management skills with java',
  },
  {
    Icon: <HireIcon4 />,
    Text: 'Complete transparency with competitive pricing',
  },
  {
    Icon: <HireIcon5 />,
    Text: 'High-quality service on your time-frame',
  },
  {
    Icon: <HireIcon6 />,
    Text: 'Flexible engagement models offering complete maintenance & support.',
  },
]
export const Homedata = [
  {
    list: 'On time delivery',
  },
  {
    list: 'High quality work as per your needs',
  },
  {
    list: 'Non-disclosure agreement',
  },
  {
    list: 'Better ROI & Smooth management',
  },
  {
    list: 'No language barrier',
  },
  {
    list: 'Latest resources and devices with proper communication channels',
  },
]

export const BenifitData = [
  {
    Title: 'Empowerment in the Global market',
    Text:
      'Since iPhone devices are popular in developed countries like the US, Europe, and the UK. Therefore, getting services from the iOS app development company will outshine them in the international market.',
  },
  {
    Title: 'Brilliant Performance',
    Text:
      'iOS apps are known for robust technologies, high performance, innovation, impeccable customer support, and excellent software. These ultimately give more customer satisfaction.',
  },
  {
    Title: 'Safe Transactions',
    Text:
      'iOS applications are ultra-safe for online transactions and prevent phishing, duplication, hacking, data loss, etc. iOS applications have complete end-to-end encryption.',
  },
  {
    Title: 'Speedier Development & Cost-Effective',
    Text:
      'iOS apps are more convenient and faster than other operating systems. Also, it saves you both time & money due to powerful programming languages.',
  },
  {
    Title: 'Better ROI For App',
    Text:
      'It is a proven fact that iOS apps generate more revenue than Android apps. Thus, with good tips & guidelines, iOS apps can provide you an added advantage.',
  },
  {
    Title: 'Excellent UI/UX Designs',
    Text:
      'iOS apps offer soothing and highly functional UI/UX design interfaces. It is the core reason for getting higher installs of iOS apps.',
  },
]

export const iOSLeftData = [
  {
    Icon: <RedIcon />,
    Text: 'Expand your digital presence and boost customer loyalty.',
  },
  {
    Icon: <YellowIcon />,
    Text: 'Generate a high revenue model and boost business sales.',
  },
  {
    Icon: <BlueIcon />,
    Text: 'Enhanced customer service and experience',
  },
  {
    Icon: <GreenIcon />,
    Text: 'Ratings & reviews to improve the area of services accordingly.',
  },
]
export const iOSRightData = [
  {
    Icon: <RedIcon />,
    Text: 'Enhanced customer retention rate.',
  },
  {
    Icon: <YellowIcon />,
    Text: 'Ability to allow booking from any part of the globe.',
  },
  {
    Icon: <BlueIcon />,
    Text: 'Helps improve brand awareness of your hotel.',
  },
  {
    Icon: <GreenIcon />,
    Text: 'Give your business a competitive edge to shine in the modern world.',
  },
]

export const WorkflowData = [
  {
    Icon: <WorkflowIcon1 />,
    Title: 'Project Analysis',
    Content:
      'Each project starts with requirement analysis and market research. We understand the clients product idea and suggest the best features and technologies based on the feasibility of the product and market demands.',
  },
  {
    Icon: <WorkflowIcon2 />,
    Title: 'Designing',
    Content:
      'After knowing your requirements our creative Ul/UX designers willcreate a highly responsive, fully customized, and interactive design of your web or app from scratch.',
  },
  {
    Icon: <WorkflowIcon3 />,
    Title: 'Development',
    Content:
      'Our talented developers use the latest technologies and powerful frameworks to build highly efficient, feature-rich, and fully functional web and apps with high-quality coding',
  },
  {
    Icon: <WorkflowIcon4 />,
    Title: 'Testing',
    Content:
      'Our QA Testers perform in-depth testing of all features to ensure your web or app is free from bugs and works at its best to deliver an exceptional user experience.',
  },
]
export const swiperdata = [
  {
    image: SliderImage,
  },
]

export const ProcessData = [
  {
    Image: ProcessImage,
    Title: 'Idea & Concept',
    Text:
      'The moment we receive an app development project, we start curating ideas for developing the app. We believe innovation is the key to a masterpiece and that is what we try to achieve. The purpose of drafting ideas is to finalize on the best fit according to requirements. Curating ideas is a tough job and so we allow you to leave it on us.',
    Icon: CouldImage,
  },
  {
    Image: ProcessImage2,
    Title: 'Feature Set',
    Text:
      'The next thing is to determine the feature set provided by the app. An app can delude from its required purpose without determining its predefined use or goal. We pay utmost attention to your requirements and goals to deliver a top-quality app that works best for your business. A proper feature set will simplify further phases of development.',
    Icon: CouldImage,
  },
  {
    Image: ProcessImage3,
    Title: 'Graphic Design',
    Text:
      'Our designers are well efficient to provide apps with unique graphic designs. The overall architecture of the app is determined in this phase. We ensure simplified yet unique designs customized as per requirements. We make sure all the necessary details are added so that the proposed app is in its place.',
    Icon: CouldImage,
  },
  {
    Image: ProcessImage4,
    Title: 'App Development',
    Text:
      'The next thing is to determine the feature set provided by the app. An app can delude from its required purpose without determining its predefined use or goal. We pay utmost attention to your requirements and goals to deliver a top-quality app that works best for your business. A proper feature set will simplify further phases of development.',
    Icon: CouldImage,
  },
  {
    Image: ProcessImage5,
    Title: 'Testing',
    Text:
      'Our designers are well efficient to provide apps with unique graphic designs. The overall architecture of the app is determined in this phase. We ensure simplified yet unique designs customized as per requirements. We make sure all the necessary details are added so that the proposed app is in its place.',
    Icon: CouldImage,
  },
  {
    Image: ProcessImage6,
    Title: 'Launch',
    Text:
      'Now that your app is ready to launch, our services do not end here. We give full-fledged support to maintain and grow your app reach in the app store. Our technical support will ensure your time and money are utilized for higher productivity.',
    Icon: CouldImage,
  },
]

export const IndustryData = [
  {
    Image: <IndustriesIcon />,
    Title: 'Education',
  },
  {
    Image: <IndustriesIcon1 />,
    Title: 'Real Estate',
  },
  {
    Image: <IndustriesIcon2 />,
    Title: 'Medical & Health Care',
  },
  {
    Image: <IndustriesIcon />,
    Title: 'Education & Learning',
  },
  {
    Image: <IndustriesIcon1 />,
    Title: 'Fitness & Wellness',
  },
  {
    Image: <IndustriesIcon2 />,
    Title: 'Banking & Finance',
  },
  {
    Image: <IndustriesIcon />,
    Title: 'Social Networking',
  },
  {
    Image: <IndustriesIcon1 />,
    Title: 'E-Commerce',
  },
  {
    Image: <IndustriesIcon2 />,
    Title: 'Logistics & Distributions',
  },
  {
    Image: <IndustriesIcon />,
    Title: 'Energy And Utilities',
  },
  {
    Image: <IndustriesIcon1 />,
    Title: 'Sports & Gamers',
  },
  {
    Image: <IndustriesIcon2 />,
    Title: 'Media & Entertainment',
  },
]

export const Siderdata = [
  {
    Icon: TechnologyImage,
    Text: 'SQlite Database',
  },
  {
    Icon: TechnologyImage1,
    Text: 'Swift Language',
  },
  {
    Icon: TechnologyImage2,
    Text: 'XCode Editor',
  },
  {
    Icon: TechnologyImage3,
    Text: 'Cocoa and Cocoa Touch Framework',
  },
  {
    Icon: TechnologyImage4,
    Text: 'Alamofire Networking',
  },
]
export const iOSFaqdata = [
  {
    id: 1,
    Number: '1.',
    Title: 'What types of iOS app solutions do you offer?',
    Text:
      'Our iPhone app development in India offers different iOS app solutions like app porting, testing & QA, web-based app development, TV app development, hybrid & customized native iOS app development, social networking app creation, mobile advertising apps, app watch development, and so on.',
  },
  {
    id: 2,
    Number: '2.',
    Title: 'What is the cost of iPhone app development services?',
    isDetails: true,
    mainContent: [
      {
        title:
          'For any startup aspiring for limited features, the price range is $15k to $25K',
      },
      {
        title:
          'For an MVP wanting more functionalities, the price range is $25K to $40K.',
      },
      {
        title:
          'For any affluent business needing a personalized iOS app, the price range is at least $40K.',
      },
    ],
    isContent: true,
    bottomText: [
      {
        text:
          'Additional cost comprises app maintenance, server deployment, and app up-gradation.',
      },
      {
        text:
          'Contact our team at an iOS app development company in India for further details.',
      },
    ],
  },
  {
    id: 3,
    Number: '3.',
    Title: 'Is your hiring model flexible?',
    Text:
      'Yes, we offer flexible engagement models which highlight the association between you and your skilled team of developers. We assure you of the quality deliverables and output transparency.',
  },
  {
    id: 4,
    Number: '4.',
    Title: 'What technologies do you use at your iOS app development company?',
    Text: 'Our iOS app development team uses the following tools:',
  },
  {
    id: 5,
    Number: '5.',
    Title: 'Would we get assistance to upload apps on the App store?',
    Text:
      'Definitely, yes. Our iOS app development company takes full responsibility for uploading the iOS app on the App store with proper assistance. It is a part of our iOS app development services only.',
  },
  {
    id: 6,
    Number: '6.',
    Title: 'How long does it take to build an iOS app?',
    Text:
      'The timeframe to build an iOS app depends upon various factors such as app design, requirements, and features. Usually, it takes around two months to create and design the app and later launch it publicly on the App store',
  },
]

export const SiderServicedata = [
  {
    Icon: TechnologyImage2,
    Text: 'PlayUp',
  },
  {
    Icon: TechnologyImage2,
    Text: 'eBay',
  },
  {
    Icon: TechnologyImage2,
    Text: 'NDTV Imagine',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Coca-Cola',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Microsoft',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Sony',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Nokia',
  },
  {
    Icon: TechnologyImage2,
    Text: 'McAfee',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Stanford HCI Group',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Ferrari',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Nike',
  },
  {
    Icon: TechnologyImage2,
    Text: 'The English Premier League',
  },
  {
    Icon: TechnologyImage2,
    Text: 'Xerox',
  },
  {
    Icon: TechnologyImage2,
    Text: 'PayPal',
  },
]
export const ExploreData = [
  {
    Title: 'Low6-Sports Betting App Case Study',
    Text:
      "Let's make a nice big leafy tree. You have to make those little noises or it won't work. Let's put some happy little clouds in our world. We don't really know where this goes - and I'm not sure we really care. When you do it your way you can go anywhere you choose. No worries. No cares. Just float and wait for the wind to blow you around.",
  },
  {
    Title: 'YourTime -E-Learning App Case Study',
    Text:
      "Let's make a nice big leafy tree. You have to make those little noises or it won't work. Let's put some happy little clouds in our world. We don't really know where this goes - and I'm not sure we really care. When you do it your way you can go anywhere you choose. No worries. No cares. Just float and wait for the wind to blow you around.",
  },
  {
    Title: 'Tropical Healthcare - Video Consulting App Case Study',
    Text:
      "Let's make a nice big leafy tree. You have to make those little noises or it won't work. Let's put some happy little clouds in our world. We don't really know where this goes - and I'm not sure we really care. When you do it your way you can go anywhere you choose. No worries. No cares. Just float and wait for the wind to blow you around.",
  },
]

export const AchievementSiderdata = [
  {
    Icon: Certification1,
  },
  {
    Icon: Certification2,
  },
  {
    Icon: Certification3,
  },
  {
    Icon: Certification1,
  },
  {
    Icon: Certification2,
  },
  {
    Icon: Certification3,
  },
]

export const NavData = [
  {
    Title: 'Travel & Hospitality',
    id: 'Travel',
  },
  {
    Title: 'Real Estate',
    id: 'Real',
  },
  {
    Title: 'Medical & Health Care',
    id: 'Medical',
  },
  {
    Title: 'Education & Learning',
    id: 'Education',
  },
  {
    Title: 'Fitness & Wellness',
    id: 'Fitness',
  },
  {
    Title: 'Media & Entertainment',
    id: 'Media',
  },
  {
    Title: 'Energy and Utilities',
    id: 'Energy',
  },
  {
    Title: 'Sports & Games',
    id: 'Sports',
  },
  {
    Title: 'Banking & Finance',
    id: 'Banking',
  },
  {
    Title: 'Social Networking',
    id: 'Social',
  },
  {
    Title: 'Logistics & Distributions',
    id: 'Logistics',
  },
  {
    Title: 'E-Commerce',
    id: 'E-Commerce',
  },
]

export const CertificationData = [
  {
    Logo: IcoLogoBlue,
  },
  {
    Logo: IcoLogoBlue,
  },
  {
    Logo: IcoLogoBlue,
  },
]
export const TravelData = [
  {
    id: 'Travel',
    Images: TraveLImg,
    Title: 'Travel & Hospitality',
    isDetails: true,
    mainContent: [
      {
        title: 'On-Demand Taxi Apps',
      },
      {
        title: 'Hotel Management Software',
      },
      {
        title: 'GDS based Travel Portals',
      },
      {
        title: 'Inventory based Travel Portals',
      },
      {
        title: 'Hotel Booking Platforms',
      },
      {
        title: 'Trip Booking Platform',
      },
      {
        title: 'Activities Booking Platform',
      },
      {
        title: 'Travel Planning Apps',
      },
    ],
    id: 'Travel',
  },
  {
    id: 'Real',
    Images: RealEstateImg,
    Title: 'Real Estate',
    isDetails: true,
    id: 'Real',
    mainContent: [
      {
        title: 'Property Search Portals',
      },
      {
        title: 'Real Estate Agent Platform',
      },
      {
        title: 'Society Management Software',
      },
      {
        title: 'Rental Management Apps',
      },
      {
        title: 'Construction Management Software',
      },
      {
        title: 'Property Management Software',
      },
    ],
  },
  {
    id: 'Medical',
    Images: MedicalImg,
    Title: 'Medical & Health Care',
    isDetails: true,
    mainContent: [
      {
        title: 'Healthcare Services App',
      },
      {
        title: ' Patient Health Tracking Apps',
      },
      {
        title: 'Hospital Management System',
      },
      {
        title: 'Chiropractic Solutions ',
      },
      {
        title: 'Clinical Communication System',
      },
      {
        title: 'Pharmacy Solutions',
      },
      {
        title: 'Laboratory Service App',
      },
      {
        title: 'Health and Fitness Apps',
      },
    ],
  },
  {
    id: 'Education',
    Images: EducationLearning,
    Title: 'Education & Learning',
    isDetails: true,
    mainContent: [
      {
        title: 'School Management System',
      },
      {
        title: 'Learning Management System',
      },
      {
        title: 'Virtual Classroom',
      },
      {
        title: 'Student’s Portal',
      },
      {
        title: 'Academic Expert’s Portal',
      },
      {
        title: 'Social Emotional Learning',
      },
    ],
  },
  {
    id: 'Fitness',
    Images: Fitness,
    Title: 'Fitness & Wellness',
    isDetails: true,
    mainContent: [
      {
        title: 'Fitness Tracking Apps',
      },
      {
        title: ' Nutrition and Diet Apps',
      },
      {
        title: 'AI-based Personal Trainer Apps',
      },
      {
        title: 'Fitness Gamification Apps',
      },
      {
        title: 'Fitness Wearable Watch App',
      },
      {
        title: 'Workout and Exercise App',
      },
    ],
  },
  {
    id: 'Media',
    Images: MedicaImage,
    Title: 'Media & Entertainment',
    isDetails: true,
    mainContent: [
      {
        title: 'Entertainment App',
      },
      {
        title: 'Live Streaming App',
      },
      {
        title: 'Customized Mobile App',
      },
      {
        title: 'Instant Video Sharing App',
      },
      {
        title: 'News App',
      },
      {
        title: 'Music Streaming App',
      },
    ],
  },
  {
    id: 'Energy',
    Images: EnergyUtilities,
    Title: 'Energy and Utilities',
    isDetails: true,
    mainContent: [
      {
        title: 'Inventory Tracking Apps',
      },
      {
        title: 'Task Monitoring System',
      },
      {
        title: 'IoT-Based Custom Automation Tools',
      },
      {
        title: 'Process Automation Tools',
      },
      {
        title: 'Capacity Planning Software',
      },
      {
        title: 'Solar Energy Management App',
      },
    ],
  },
  {
    id: 'Sports',
    Images: SportsGames,
    Title: 'Sports & Gamers',
    isDetails: true,
    mainContent: [
      {
        title: 'Sports Betting App',
      },
      {
        title: 'Live Sports Tracking App',
      },
      {
        title: 'Sports Event Booking App',
      },
      {
        title: 'Team Management App',
      },
      {
        title: 'Live Streaming App',
      },
      {
        title: 'E-Sports App',
      },
    ],
  },
  {
    id: 'Banking',
    Images: BankingFinance,
    Title: 'Banking & Finance',
    isDetails: true,
    mainContent: [
      {
        title: 'Mobile Banking Solution',
      },
      {
        title: 'Finance Management Solution',
      },
      {
        title: 'CRM Software',
      },
      {
        title: 'Fintech App Solution',
      },
      {
        title: 'Mutual fund Marketplace',
      },
      {
        title: 'Stock Trading Marketplace',
      },
      {
        title: 'Cryptocurrency Wallet Development',
      },
      {
        title: 'Loan/Insurance Management Solution',
      },
    ],
  },
  {
    id: 'Social',
    Images: SocialNetworking,
    Title: 'Social Networking',
    isDetails: true,
    mainContent: [
      {
        title: 'Social Media App',
      },
      {
        title: 'Dating App',
      },
      {
        title: 'Clone Appe',
      },
      {
        title: 'Social Community App',
      },
      {
        title: ' Photo Video Sharing App',
      },
      {
        title: 'Chatting App',
      },
    ],
  },
  {
    id: 'Logistics',
    Images: LogisticDistribution,
    Title: 'Logistics & Distributions',
    isDetails: true,
    mainContent: [
      {
        title: 'Freight Rate Comparison App',
      },
      {
        title: 'Warehouse Management App',
      },
      {
        title: 'Truck Direction Routing App',
      },
      {
        title: 'Billing Software',
      },
      {
        title: 'Supply Chain Software',
      },
      {
        title: 'Shipping Order Processing Tools',
      },
      {
        title: 'Supplier Management Solutions',
      },
    ],
  },
  {
    id: 'E-Commerce',
    Images: ECommerce,
    Title: 'E-Commerce',
    isDetails: true,
    mainContent: [
      {
        title: 'B2B eCommerce Apps and Marketplaces',
      },
      {
        title: 'B2C eCommerce Apps and Marketplaces',
      },
      {
        title: 'Multi-vendor eCommerce Portal',
      },
      {
        title: 'Inventory Management app',
      },
      {
        title: 'Retail eCommerce Apps',
      },
      {
        title: 'AI-based eCommerce Tools',
      },
      {
        title: 'Service Marketplace Apps',
      },
      {
        title: 'Retail ERP software management',
      },
    ],
  },
]

export const ProjectData = [
  {
    Image: ProjectImage1,
    Path: '/',
    Title: 'Wine Online Delivery',
    Content:
      ' Wine Online Delivery is the premier source for Top Rated Wines and Spirits with the largest selections of Wine, spirits, and Beer in the',
    isImage: true,
    smallImage: [
      {
        path: '/',
        img: WebImg,
        title: 'Browser',
      },
    ],
  },
  {
    Image: ProjectImage2,
    Path: '/',
    Title: 'Datapp',
    Content:
      ' Being a Dad is both rewarding and challenging. So here is the App will help you to find connect and meet with like-minded dads nearby..',
    isImage: true,
    smallImage: [
      {
        path: '/',
        img: AndroidSmallImage,
        title: 'Android',
      },
      {
        path: '/',
        img: AppleSmallImage,
        title: 'IOS',
      },
    ],
  },
  {
    Image: ProjectImage3,
    Path: '/',
    Title: 'Revive Healthcare',
    Content:
      "St Martin's accommodate up to 19 permanent residential placements in our residential care home. Revive Heath Care Ltd is an informative.",
    isImage: true,
    smallImage: [
      {
        path: '/',
        img: WebImg,
        title: 'Browser',
      },
    ],
  },
]
