import Whitelogo from '../assets/WhiteLogo.svg'
import Blacklogo from '../assets/Logo.svg'
import Map from '../assets/Footer/Logo-Map.png'
import AndroidBlackImage from '../assets/Header/Hire-Android-App-Developer-Black.png'
import BannerShape1 from '../assets/Home/Shape1.png'
import BannerShape2 from '../assets/Home/Shape2.png'
import Arrow from '../assets/Header/Arrow-Down-Sign-To-Navigate.svg'
import ArrowUp from '../assets/Header/Arrow.png'
import HireAndroidBanner from '../assets/Hire_pages_images/Android-Banner-Image.png'
import LeftShape from '../assets/Hire_pages_images/Hire-Top-Bg.png'
import Shape from '../assets/Hire_pages_images/Red-Shape.png'
import ExportShape from '../assets/Hire_pages_images/Export-Left-Shape.png'
import HireBanner from '../assets/Hire_pages_images/Android-Hire-Image.png'
import ServiceBg from '../assets/Hire_pages_images/Service-Blog.png'
import HireFlowImage from '../assets/Hire_pages_images/Hiring-Image.png'
import TalkToExport from '../assets/Hire_pages_images/Talk-Expert-Bg.png'
import DoteShpeLeft from '../assets/Hire_pages_images/Shape3.png'
import DoteShpeRight from '../assets/Hire_pages_images/Shape4.png'
import HomeBanner1 from '../assets/Home/Home-Banner1.png'
import CornerImage from '../assets/Hire_pages_images/Rounded-Corner-Image.png'
import RightShape from '../assets/Hire_pages_images/Right-Shape-Image.png'
import ServiceImage from '../assets/Hire_pages_images/Android-Service-Image.png'
import HireServiceBg from '../assets/Hire_pages_images/Hiring-Blog-Bg.png'
import DevelopmentImg from '../assets/Hire_pages_images/Android-Playstore-Image.png'
import SideArrow from '../assets/Hire_pages_images/Side-Arrow.png'
import BlackDownArrow from '../assets/Hire_pages_images/Black-Down-Arrow.png'
import MilestoneImageShape1 from '../assets/Home/Milestone-Shape1.png'
import MilestoneImageShape2 from '../assets/Home/Milestone-Shape2.png'
import WorkflowShape from '../assets/Home/Workflow-Shape.png'
import WorkFlowBg from '../assets/Home/Work-Bg.png'
import PolyShape from '../assets/Home/Poly-Shape.png'
import BuildImage from '../assets/Home/Build-Img.png'
import HomeBanner2 from '../assets/Home/Home-Banner2.png'
import IOSServiceBanner from '../assets/Service_pages_images/Ios-Service-Banner.png'
import IOSServiceBg from '../assets/Service_pages_images/Services-Bg.png'
import LeftArrow from '../assets/Home/Left-Arrow.svg'
import RightArrow from '../assets/Home/Right-Arrow.svg'
import EstimateBg from '../assets/Home/Estimate-Bg.png'
import EstimateBgNew from '../assets/Home/Estimate-Bg-New.png'
import TechnologiBG from '../assets/Service_pages_images/Technology-Bg.png'
import MapBG from '../assets/Service_pages_images/Map-Bg.png'
import GrayArrow from '../assets/Gray-Right.png'
import QuoteLeft from '../assets/Quote-Left.png'
import QuoteRight from '../assets/Quote-Right.png'
import HomeBannerBg from '../assets/Home/Home-Banner-Bg.png'
import HomeBanner4 from '../assets/Home/Home-Banner4.png'
import HomeBanner3 from '../assets/Home/Home-Banner3.png'
import AchivementBg from '../assets/Home/Achivement-Bg.png'
import IndustiesBG from '../assets/Home/Indus-Bg.png'
import RedLeftArrow from '../assets/Red-Left.png'
import RedRightArrow from '../assets/Red-Right.png'
import ProjectLeftImage from '../assets/Home/Project-Left-Img.png'
import TechnologyShape from '../assets/Home/Tech-Shape-Bg.png'
import GrayLeft from '../assets/Gray-Left.png'
import ClientImg1 from '../assets/Client1.png'
import ClientImg2 from '../assets/Client2.png'
import ClientImg3 from '../assets/Client3.png'
import ClientImg4 from '../assets/Client4.png'
import ClientImg5 from '../assets/Client5.png'
import ClientImg6 from '../assets/Client6.png'

const Images = {
  SecondaryLogo: Whitelogo,
  PrimaryLogo: Blacklogo,
  MapLogo: Map,
  HireAndroidAppDeveloperBlackImage: AndroidBlackImage,
  BannerShape1: BannerShape1,
  BannerShape2: BannerShape2,
  DownArrow: Arrow,
  UpArrow: ArrowUp,
  HireAndroidBanner: HireAndroidBanner,
  LeftShape: LeftShape,
  RedShape: Shape,
  ExportShape: ExportShape,
  HireBanner: HireBanner,
  ServiceBg: ServiceBg,
  HireFlowImage: HireFlowImage,
  TalkToExport: TalkToExport,
  DoteShpeLeft: DoteShpeLeft,
  DoteShpeRight: DoteShpeRight,
  HomeBanner1: HomeBanner1,
  CornerImage: CornerImage,
  RightShape: RightShape,
  ServiceImage: ServiceImage,
  HireServiceBg: HireServiceBg,
  DevelopmentImg: DevelopmentImg,
  SideArrow: SideArrow,
  BlackDownArrow: BlackDownArrow,
  MilestoneImageShape1: MilestoneImageShape1,
  MilestoneImageShape2: MilestoneImageShape2,
  WorkflowShape: WorkflowShape,
  WorkFlowBg: WorkFlowBg,
  PolyShape: PolyShape,
  BuildImage: BuildImage,
  HomeBanner2: HomeBanner2,
  IOSServiceBanner: IOSServiceBanner,
  IOSServiceBg: IOSServiceBg,
  TechnologiBG: TechnologiBG,
  MapBG: MapBG,
  GrayArrow: GrayArrow,
  QuoteLeft: QuoteLeft,
  QuoteRight: QuoteRight,
  HomeBannerBg: HomeBannerBg,
  HomeBanner4: HomeBanner4,
  HomeBanner3: HomeBanner3,
  AchivementBg: AchivementBg,
  LeftArrow: LeftArrow,
  RightArrow: RightArrow,
  EstimateBg: EstimateBg,
  EstimateBgNew: EstimateBgNew,
  SecondaryLogo: Whitelogo,
  PrimaryLogo: Blacklogo,
  MapLogo: Map,
  HireAndroidAppDeveloperBlackImage: AndroidBlackImage,
  BannerShape1: BannerShape1,
  BannerShape2: BannerShape2,
  DownArrow: Arrow,
  UpArrow: ArrowUp,
  HireAndroidBanner: HireAndroidBanner,
  LeftShape: LeftShape,
  RedShape: Shape,
  ExportShape: ExportShape,
  HireBanner: HireBanner,
  ServiceBg: ServiceBg,
  HireFlowImage: HireFlowImage,
  TalkToExport: TalkToExport,
  DoteShpeLeft: DoteShpeLeft,
  DoteShpeRight: DoteShpeRight,
  HomeBanner1: HomeBanner1,
  CornerImage: CornerImage,
  RightShape: RightShape,
  ServiceImage: ServiceImage,
  HireServiceBg: HireServiceBg,
  DevelopmentImg: DevelopmentImg,
  SideArrow: SideArrow,
  BlackDownArrow: BlackDownArrow,
  MilestoneImageShape1: MilestoneImageShape1,
  MilestoneImageShape2: MilestoneImageShape2,
  WorkflowShape: WorkflowShape,
  WorkFlowBg: WorkFlowBg,
  PolyShape: PolyShape,
  BuildImage: BuildImage,
  HomeBanner2: HomeBanner2,
  IOSServiceBanner: IOSServiceBanner,
  IOSServiceBg: IOSServiceBg,
  TechnologiBG: TechnologiBG,
  MapBG: MapBG,
  GrayArrow: GrayArrow,
  QuoteLeft: QuoteLeft,
  QuoteRight: QuoteRight,
  HomeBannerBg: HomeBannerBg,
  HomeBanner4: HomeBanner4,
  HomeBanner3: HomeBanner3,
  AchivementBg: AchivementBg,
  IndustiesBG: IndustiesBG,
  RedLeftArrow: RedLeftArrow,
  RedRightArrow: RedRightArrow,
  ProjectLeftImage: ProjectLeftImage,
  TechnologyShape: TechnologyShape,
  GrayLeft: GrayLeft,
  ClientImg1: ClientImg1,
  ClientImg2: ClientImg2,
  ClientImg3: ClientImg3,
  ClientImg4: ClientImg4,
  ClientImg5: ClientImg5,
  ClientImg6: ClientImg6,
}

export default Images
