import React from "react";
import { Container, Row } from "react-bootstrap";
import { Faqdata } from "../services/data";
import { AccordationDiv, FaQContent } from "../styles/Hirepages.style";
import Accordion from "react-bootstrap/Accordion";
import TitleSection from "../components/TitleSection";

export const FaqSection = () => {
  return (
    <FaQContent>
      <Container>
        <TitleSection title={"FAQs"} />
        <Row className="align-items-center">
          <AccordationDiv>
            {Faqdata?.map((item) => {
              return (
                <>
                  {/* <Accordion
               
                  title={item.Title}
                  children={item.Text}
                /> */}
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><span>{item.Number}</span>{item.Title}</Accordion.Header>
                      <Accordion.Body>
                      
                        {item.Text}                        
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              );
            })}
          </AccordationDiv>
        </Row>
      </Container>
    </FaQContent>
  );
};
export default FaqSection;
