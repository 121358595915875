import React from 'react'
import Images from '../../assets/Images'
import { DevelopmentImage } from '../../styles/Hirepages.style'

export const DevelopmentRight = () => {
  return (
    <DevelopmentImage>
      <img src={Images.DevelopmentImg} className="img-fluid"/>
    </DevelopmentImage>
  )
}
export default DevelopmentRight