import React from "react";
import { SectionTitleWrp } from "../styles/Hirepages.style";

export const BannerTitle = ({ MainTitle, Content, IsSectionTitle, isSmallText }) => {
  return (
    <>
      {IsSectionTitle && (
        <SectionTitleWrp className="position-relative">
          {isSmallText === true ? <h2>{MainTitle}</h2> : <h1>{MainTitle}</h1>}  
          <em></em>        
          <p>{Content}</p>
        </SectionTitleWrp>
      )}
    </>
  );
};

export default BannerTitle
