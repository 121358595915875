import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BannerTitle from '../../comman/BannerTitle'
import { ContainerLeft } from '../../styles/globalStyles'
import {
  AppServiceimg,
  AppServiceWrapper,
  MiddleSlider,
  ServiceContent,
  SliderCon,
  SliderContent,
} from '../../styles/Servicespages.style'
import ServiceImg from '../../assets/Service_pages_images/Service-Inside3.png'
import HireImage from '../../assets/Service_pages_images/Hire-Image.png'
import TitleSection from '../TitleSection'
import { iOSLeftData, iOSRightData, swiperdata } from '../../services/data'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import LanguageSlider from '../../comman/LanguageSlider'

export const AppService = ({ isSection }) => {
  return (
    <>
      {isSection == true ? (
        <AppServiceWrapper className="hire_serive_sec">
          <ContainerLeft>
            <Row className="g-0 align-items-center">
              <Col lg={6} className="service_li_image">
                <BannerTitle
                  IsSectionTitle
                  MainTitle={
                    <h2>
                      Leading Companies <br /> Taking iPhone App <br />{' '}
                      Development Services
                    </h2>
                  }
                  Content={
                    'World-renowned brands trust the iOS applications for the expansion of their businesses. Moreover, the brand Apple itself represents high-end quality and security. Some of the top brands are'
                  }
                />
              </Col>
              <Col lg={6} className="service_li last">
                <AppServiceimg>
                  <img src={HireImage} className="img-fluid" />
                </AppServiceimg>
              </Col>
            </Row>
          </ContainerLeft>
          <Container>
            <SliderContent>
              <LanguageSlider isServiceSlide={true} />
            </SliderContent>
          </Container>
        </AppServiceWrapper>
      ) : (
        <AppServiceWrapper>
          <ContainerLeft>
            <Row className="g-0 align-items-center">
              <Col lg={6} className="service_li_image">
                <BannerTitle
                  IsSectionTitle
                  MainTitle={
                    <h2>
                      Our iOS/iPhone Mobile <br /> App Development <br />
                      Services
                    </h2>
                  }
                />
              </Col>
              <Col lg={6} className="service_li last">
                <AppServiceimg>
                  <img src={ServiceImg} className="img-fluid" />
                </AppServiceimg>
              </Col>
            </Row>
          </ContainerLeft>
          <ServiceContent>
            <Container>
              <TitleSection title="Our Services" />
              <Row className="service_slider align-items-center">
                <Col xl={3} lg={4} md={6} className="slider_grid">
                  <SliderCon>
                    {iOSLeftData?.map((item) => {
                      return (
                        <>
                          <div className="flex-div d-flex align-items-center">
                            <div className="svg-icon">{item.Icon}</div>
                            <h4>{item.Text}</h4>
                          </div>
                        </>
                      )
                    })}
                  </SliderCon>
                </Col>
                <Col xl={6} lg={4} md={12} className="slider_grid">
                  <MiddleSlider>
                    <div class="circle-1"></div>
                    <Swiper
                      slidesPerView={1}
                      navigation={true}
                      modules={[Navigation]}
                      loop={true}
                      className="mySwiper"
                    >
                      {swiperdata?.map((item, index) => {
                        return (
                          <SwiperSlide key={index + 1}>
                            <div className="swiper-image text-center">
                              <img src={item.image} class="img-fluid" />
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </MiddleSlider>
                </Col>
                <Col xl={3} lg={4} md={6} className="slider_grid right">
                  <SliderCon>
                    {iOSRightData?.map((item) => {
                      return (
                        <>
                          <div className="flex-div d-flex align-items-center">
                            <h4 className="text-end">{item.Text}</h4>
                            <div className="svg-icon">{item.Icon}</div>
                          </div>
                        </>
                      )
                    })}
                  </SliderCon>
                </Col>
              </Row>
            </Container>
          </ServiceContent>
        </AppServiceWrapper>
      )}
    </>
  )
}
export default AppService
