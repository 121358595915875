import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ContainerRight } from '../styles/globalStyles'
import { BlogWrapper } from '../styles/Hirepages.style'
import BlogLeft from '../components/AndroidApp/BlogLeft'
import BlogRight from '../components/AndroidApp/BlogRight'

export const BlogSection = () => {
  return (
    <BlogWrapper>
      <ContainerRight>
        <Row className="g-0 align-items-center">
          <Col lg={6} className="banner_li blog_images">
            <BlogLeft />
          </Col>
          <Col lg={6} className="banner_li blog_content">
            <BlogRight />
          </Col>
        </Row>
      </ContainerRight>
    </BlogWrapper>
  )
}
export default BlogSection
