import styled, { createGlobalStyle } from "styled-components";
import Nexa from "../assets/fonts/Nexa-Regular.ttf";
import NexaBold from "../assets/fonts/Nexa-Bold.ttf";
import NexaBook from "../assets/fonts/Nexa-Book.ttf";
import NexaExtraBold from "../assets/fonts/Nexa-ExtraBold.ttf";
import NexaLight from "../assets/fonts/Nexa-Light.ttf";
import PoppinsBold from "../assets/fonts/Poppins-Bold.ttf";
import PoppinsRegular from "../assets/fonts/Poppins-Regular.ttf";
import PoppinsMedium from "../assets/fonts/Poppins-Medium.ttf";
import PoppinsSemiBold from "../assets/fonts/Poppins-SemiBold.ttf";
import SeguiBold from "../assets/fonts/segui-bold.ttf";
import SeguiSemibold from "../assets/fonts/segui-semibold.ttf";
import { SectionTitleWrp } from "./Hirepages.style";
import Images from "../assets/Images";
import { MegaMenuMain } from "./Header.style";
import { MegaMenu } from "./Header.style";

export const GlobalStyle = createGlobalStyle`
   @font-face {
        font-family: 'Nexa';
        src: url(${Nexa}) format('truetype');
        font-style: normal;
    }

    @font-face {
        font-family: 'NexaBold';
        src: url(${NexaBold}) format('truetype');
        font-style: normal;
    }

    @font-face {
        font-family: 'NexaBook';
        src: url(${NexaBook}) format('truetype');
        font-style: normal;
    }

    @font-face {
        font-family: 'NexaExtraBold';
        src: url(${NexaExtraBold}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'NexaLight';
        src: url(${NexaLight}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'PoppinsBold';
        src: url(${PoppinsBold}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'PoppinsRegular';
        src: url(${PoppinsRegular}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'PoppinsMedium';
        src: url(${PoppinsMedium}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'PoppinsSemiBold';
        src: url(${PoppinsSemiBold}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'SeguiSemibold';
        src: url(${SeguiSemibold}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'SeguiBold';
        src: url(${SeguiBold}) format('truetype');
        font-style: normal;
    }

    html , body , #root {
      height: 100%;
      font-family: 'Nexa';
    }

      * , body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Nexa';
      }

      ul{
        list-style: none;
        padding: 0;
      }
    h1 , h2 , h3 , h4 , h5, h6 , p{
        margin-bottom: 0;
    }

    a{
        text-decoration: none;
    }

    em.yellow {
      background: #fbd301 !important;
    }
  
    em.red {
        background: #dd3a31 !important;
    }
  
    em.blue {
        background: #0098d1 !important;
    }
  
    em.green {
        background: #a4c43a !important;
    }
    em{
      height: 6px;
      width: 126px;
      background: #FBD301;
      display: block;
      margin: 20px 0;
      border-radius: 50px;
      box-shadow: 2px 0px 13px 0px rgb(251 211 1 / 79%);
      -webkit-box-shadow: 2px 0px 13px 0px rgb(251 211 1 / 79%);
      -webkit-animation: linenaimate 1s infinite alternate;
    animation: linenaimate 1s infinite alternate;
    }
    
    @-webkit-keyframes linenaimate {
      0% {
        width: 0;
      }
      100% {
        width: 126px;
      }
    }

    @keyframes linenaimate {
      0% {
        width: 0;
      }
      100% {
        width: 126px;
      }
    }

  .container{
      margin: auto;
      padding: 0 15px;
  }
   
  .top-arrow{
      position: fixed;
      bottom: 25px;
      right: 25px;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: #DD3A31;
      color: #fff !important;
      border-radius: 50px;
      padding: 0;
      width: 60px;
      height: 60px;
      line-height: 49px;
      border: 5px solid #fff !important;
      box-shadow: 0 0px 19px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
      transition: 0.2s;
      z-index: 9;
      text-align: center;
      svg{
        width: 25px;
        height: 25px;
      }
      &:hover{
        background-color: #000;
      }
      &:focus{
        outline: none;
        box-shadow: unset;
      }
  }
  .Noscroll {overflow: hidden;}
  p{
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      font-family: 'SeguiSemibold';
      margin-bottom: 0;
      color: #71788A;
  }
  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 55px;
    font-family: "PoppinsBold";
  }
  h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 75px;
    font-family: "PoppinsBold";
  }  
  h4{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #212121;
    font-family: 'PoppinsSemiBold';
  }
  @media (min-width: 1024px){
    .container {
        max-width: 960px;
    }
  }
  @media (min-width: 1199px){
    .container {
        max-width: 1050px;
    }
  }
  @media (min-width: 1366px){
    .container {
        max-width: 1220px;
    }
  }
  @media (min-width: 1600px){
    .container {
        max-width: 1400px;
    }
  }  
  @media (min-width: 1800px){
    .container {
        max-width: 1700px;
    }
  }

  @media (max-width: 1600px) {
    h1 {
      font-size: 30px;
      line-height: 1.5;
    }
    h2{
      font-size: 28px;
      line-height: 1.5;
    }
    p{
      font-size: 16px;      
      line-height: 1.5;
    }
  }
  @media (max-width: 1199px) {
    h1{
      font-size: 26px;
    }
  }
  @media (max-width: 991px) {
    .nav::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }

    .nav::-webkit-scrollbar
    {
      width: 8px;
      background-color: #F5F5F5;
    }

    .nav::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #000000;
    }
    .nav {
      padding-top: 50px;
        overflow-y: auto;
        margin-top: 0;
        .logo-content {
          display: none;
        }
      .megamenu-li{
       
        h3{
          padding:5px 20px!important;
          font-size: 13px !important;
          display: none;
          &::before{
            display: none !important;
          }
        }
      }
      ul{
        width: 100%;
        li{
          border-bottom: 1px solid #ddd;
          &:last-child{
            border-bottom:0
          }
        }
      }
      .nav-link{
        color:#000;
        font-family: 'SeguiBold';
        .down-arrow{
          margin-left: 10px;
        }
      }
      .up-arrow {
        display: none;
      }
      .main_img,.hover_img{
        display: none !important;
      }
      ${MegaMenuMain}{
        .row{
          margin:0
        }
        ${MegaMenu}{
          padding:  0!important;
          .megamenu-li{
            padding:0;
            margin-bottom:0;
            h3{
              display: none;
            }
            .megamenu-list{
              margin:0;
              li{
                a{
                  padding:0 20px;
                }
              }
            }
          }
        }
        .first-menu{
          padding: 0!important;
          ${MegaMenu}{
            padding: 20px 0!important;
          }
          .megamenu-li {
            .megamenu-list {
              margin-bottom:10px;
            }
            h3{
              padding-left: 15px;
              display: block;
              font-size:16px !important;
            }
          }
          .megamenu-list {
          
            li{
              padding: 5px 0 5px;
              &::before{
                display: none;
              }
              a{
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
    h4{
      font-size: 18px;
      line-height: 1.5;
    }
    .bread-crumbs.white-bg header{
        border-radius:0 0 30px 30px;
    }
    .order-m-1{
      order: 1;
    }
    .order-m-2{
      order: 2;
    }
  }
  @media (max-width: 575px) {
    .offcanvas-start{
      width: 305px;
    }
    h1 {
      font-size: 19px;
    }
    h2{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
    h4{
      font-size: 16px;
    }
  }
`;

export const ContainerRight = styled.section`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 991px) {
    padding-right: calc(50% - 456px);
  }
  @media (min-width: 1024px) {
    padding-right: calc(50% - 455px);
  }
  @media (min-width: 1199px) {
    padding-right: calc(50% - 500px);
  }
  @media (min-width: 1366px) {
    padding-right: calc(50% - 586px);
  }
  @media (min-width: 1600px) {
    padding-right: calc(50% - 674px);
  }
  @media (min-width: 1800px) {
    padding-right: calc(50% - 832px);
  }
`;
export const ContainerLeft = styled.section`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 991px) {
    padding-left: calc(50% - 456px);
  }
  @media (min-width: 1024px) {
    padding-left: calc(50% - 455px);
  }
  @media (min-width: 1199px) {
    padding-left: calc(50% - 500px);
  }
  @media (min-width: 1366px) {
    padding-left: calc(50% - 586px);
  }
  @media (min-width: 1600px) {
    padding-left: calc(50% - 674px);
  }
  @media (min-width: 1800px) {
    padding-left: calc(50% - 832px);
  }
`;
export const Section = styled.section``;
export const LoaderMain = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #000;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderLogo = styled.div`
  position: relative;

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: shine 2.5s infinite cubic-bezier(0.42, 0, 0.58, 1);
    background: rgba(255, 255, 255, 0.8);
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgb(0 0 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%) skew(-30deg);
    }
    100% {
      transform: translateX(200%) skew(-30deg);
    }
  }
`;

export const SectionTitle = styled.div`
  h3 {
    padding-left: 20px;
    border-left: 3px solid #dd3a31;
    margin-bottom: 20px;
    display: block;
    font-family: "PoppinsSemiBold";
    font-size: 35px;
  }
  p {
    color: #000000;
  }
  @media (max-width: 1600px) {
    h3 {
      font-size: 25px;
    }
  }
  @media (max-width: 991px) {
    h3 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
`;

export const TestomonialWrapper = styled.div`
  padding: 50px 0;
  ${SectionTitleWrp} {
    text-align: center;
    margin: auto;
    em {
      margin: 20px auto;
    }
  }
  @media (max-width: 1440px) {
    padding: 0 0 50px;
  }
`;
export const TestomonialSwiperArrow = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .slider-arrow {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
    button {
      background: #e7e8e7;
      width: 50px;
      height: 50px;
      display: block;
      border-radius: 50%;
      color: #000;
      opacity: 1;
      font-size: 25px;
      line-height: 50px;
      text-align: center;
      margin: 0 7px;
      border: 0;
      &:hover {
        background: #dd3a31;
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 1199px) {
    bottom: -50px;
  }
  @media (max-width: 575px) {
    bottom: 0;
  }
  @media (max-width: 350px) {
    bottom: -50px;
  }
`;
export const TestomonialSlider = styled.div`
  margin: 100px 0 0;
  position: relative;
  height: 620px;
  margin-top: 0;
  background: url(${Images.MapBG}) no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 50px;
  .quote-left svg,
  .quote-right svg {
    width: 35px;
    height: 35px;
    margin: 0 -12px;
  }
  .quote-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  .quote-left svg path,
  .quote-right svg path {
    fill: #dd3a31;
  }

  .quote-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  .client-single {
    margin-top: 20px;
    text-align: center;
    position: absolute;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .client-info,
  .client-comment {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .client-single.inactive .client-comment,
  .client-single.inactive .client-info {
    display: none;
  }

  .client-single.inactive .client-comment,
  .client-single.inactive .client-info {
    opacity: 0;
    visibility: hidden;
  }

  .client-single.position-1 {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
  }

  .client-single.position-2 {
    left: -40px;
    top: 105px;
  }

  .client-single.position-3 {
    left: 260px;
    top: 120px;
    -webkit-transform: scale(0.4) !important;
    transform: scale(0.4) !important;
  }

  .client-single.position-4 {
    left: 55px;
    top: 380px;
  }

  .client-single.position-5 {
    top: 30px;
    right: 75px;
  }

  .client-single.position-6 {
    top: 245px;
    right: 110px;
  }

  .client-single.position-7 {
    top: 430px;
    right: 235px;
    -webkit-transform: scale(0.4) !important;
    transform: scale(0.4) !important;
  }

  .client-single.active {
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    z-index: 0;
    width: 70%;
  }

  .client-single.active .client-comment,
  .client-single.active .client-info {
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.55);
    z-index: 1;
  }

  .client-single.active .client-img {
    width: 160px;
    height: 160px;
    margin: 0 auto 24px;
    position: relative;
  }

  .client-single .client-img:before {
    border-radius: 100%;
    content: "";
    padding: 5px;
    width: 160px;
    height: 160px;
    position: absolute;
    z-index: -1;
    animation: rotate 3s linear reverse infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg) translateX(15px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(15px) rotate(-360deg);
    }
  }

  .client-img.red:before {
    top: -13px;
    left: 10px;
    position: absolute;
    z-index: -1;
    background: #dd3a31;
  }

  .client-img.yellow:before {
    top: -16px;
    left: 16px;
    position: absolute;
    z-index: -1;
    background: #fbd301;
  }

  .client-img.blue:before {
    top: -3px;
    left: -24px;
    position: absolute;
    z-index: -1;
    background: #0098d1;
  }

  .client-img.green:before {
    top: 11px;
    left: -19px;
    position: absolute;
    z-index: -1;
    background: #a4c439;
  }

  .client-single .client-img img {
    width: 150px;
    border-radius: 50%;
    cursor: pointer;
  }

  .client-single.active .client-img img {
    max-width: 160px;
    margin: 0 auto 24px;
    border: 0;
  }

  .client-comment {
    padding: 0 30px;
    position: relative;
    max-width: 70%;
    margin: 35px auto;
  }

  .client-comment p {
    font-size: 18px;
    line-height: 30px;
    color: #000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  .client-comment h3 {
    font-size: 22px;
    line-height: 32px;
    color: #505b6d;
  }

  .client-comment span i {
    font-size: 60px;
    color: #0084ff;
    margin: 40px 0 24px;
    display: inline-block;
  }

  .client-info h3 {
    color: #212121;
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 30px;
  }

  .client-info p {
    color: #dd3a31;
    text-transform: capitalize;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .left_quote {
    color: #dd3a31;
    position: absolute;
    top: 0;
    left: 0;
  }

  .right_quote {
    color: #dd3a31;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 1199px) {
    margin-bottom: 0;
    padding-bottom: 50px;
    .client-comment {
      max-width: 100%;
    }
    .client-info h3 {
      font-size: 23px;
    }
    .client-single.position-6 {
      top: 345px;
      right: 50px;
    }
    .client-single.position-3 {
      left: 160px;
      top: 70px;
    }
  }

  @media (max-width: 991px) {
    .client-single.active .client-img img {
      max-width: 100px;
    }
    .client-single .client-img:before {
      width: 110px;
      height: 110px;
    }
    .client-single.active .client-img {
      width: 100px;
      height: 100px;
    }
    .client-single.position-3 {
      left: 70px;
      top: 20px;
    }
    .client-single.position-7 {
      top: 461px;
      right: 200px;
    }
    .client-comment p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @media (max-width: 767px) {
    margin-top: 0;
    .client-single.position-2 {
      left: -40px;
      top: 215px;
    }
    .client-single.position-3 {
      left: -10px;
      top: 81px;
    }
    .client-single.position-4 {
      left: -15px;
      top: 390px;
    }
    .client-single.position-5 {
      top: 100px;
      right: -24px;
    }
    .client-single.position-6 {
      top: 275px;
      right: -30px;
    }
    .client-single.position-7 {
      top: 438px;
      right: 11px;
    }
    .client-single .client-img img {
      width: 110px;
    }
  }
  @media (max-width: 575px) {
    .client-comment {
      margin: 20px auto;
    }
    .client-comment p {
      font-size: 14px;
    }
    .client-single.position-2 {
      left: -10px;
      top: 215px;
    }
    .client-single.position-6 {
      top: 275px;
      right: -10px;
    }
    .client-single .client-img img {
      width: 71px;
    }
    .client-single .client-img:before {
      width: 60px;
      height: 60px;
    }
    .client-single.active .client-img {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 350px) {
    .client-single.active {
      top: 5%;
    }
  }
`;
