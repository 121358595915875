import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

function MainButton(props) {
  const {
    title,
    isPrimary,
    style,
    Btnstyle,
    isTitle,
    isIcon,
    IconMain,
    onClick,
  } = props

  const ButtonWrapper = styled.div`
    & button {
      background-color: ${(props) =>
        isPrimary ? '#FBD301' : '#212121'}!important;
      border-radius: 100px;
      padding: 15px 28px;
      border: 0;
      font-size: 19px;
      color: ${(props) => (isPrimary ? '#212121' : '#fff')}!important;
      box-shadow: 0 11px 18px rgb(0 0 0 / 18%), 0 3px 25px rgb(0 0 0 / 9%);
      font-family: 'SeguiBold';

      &:hover {
        background-color: ${(props) =>
          isPrimary ? "#dd3a31" : "#dd3a31"}!important;
           color: ${(props) => (isPrimary ? "#fff" : "#fff")}!important;
      }
      &:focus {
        box-shadow: none !important;
        background-color: ${(props) => (isPrimary ? '#000' : '#212121')};
      }
      svg {
        fill: #2549a7;
      }
      @media (max-width: 1440px) {
        font-size: 16px;
      }
    }
  `

  return (
    <ButtonWrapper style={style}>
      {isTitle && (
        <Button style={Btnstyle} onClick={onClick}>
          {title}
        </Button>
      )}
      {isIcon && <div> {IconMain} </div>}
    </ButtonWrapper>
  )
}

export default MainButton
