import React from "react";
import TalkExpert from "../components/AndroidApp/TalkExpert";

export const TalkExpertSection = ({ isService }) => {
  return (
    <>
      {isService == true ? (        
        <TalkExpert
          isBtnText={true}
          Title="Talk to our experts"
          Text="Would you like to talk to our experts about your project?"
        />
      ) : (
        <TalkExpert
          Title="Thinking of building an app?"
          Text="You can trust our team of Android app development for building secure and high-performance iOS device apps. 
    Get Professional Help!! - Get in touch with the next great Android app developer."
        />
      )}
    </>
  );
};
export default TalkExpertSection;
