import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { ArrayObject, Faqdata, iOSFaqdata } from '../../services/data'
import { AccordationDiv, FaQContent } from '../../styles/Hirepages.style'
import TitleSection from '../TitleSection'
import Accordion from 'react-bootstrap/Accordion'

export const IOSServiceFaq = () => {
  return (
    <FaQContent>
      <Container>
        <TitleSection title={'FAQs'} />
        <Row className="align-items-center">
          <AccordationDiv>
            {iOSFaqdata?.map((item ,index) => {
              
              return (
                <>
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Item eventKey={item.id}>
                      <Accordion.Header>
                        <span>{item.Number}</span>
                        {item.Title}
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.Text}

                        {item.isDetails === true ? (
                          <ul>
                            {item.mainContent.map((Details, index) => {
                              return <li>{Details.title}</li>
                            })}
                          </ul>
                        ) : null}
                        {item.isContent === true ? (
                          <div>
                            {item.bottomText.map((Details, index) => {
                              return <p>{Details.text}</p>
                            })}
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )
            })}
          </AccordationDiv>
        </Row>
      </Container>
    </FaQContent>
  )
}
export default IOSServiceFaq
