import React from 'react'
import Images from '../../assets/Images'
import { ServiceImage } from '../../styles/Hirepages.style'

export const ServiceLeft = () => {
  return (
    <ServiceImage>
        <img src={Images.ServiceImage} class="img-fluid"/>
    </ServiceImage>
  )
}
export default ServiceLeft