import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { KeyBenifitList } from '../services/data'
import { KeyBenifitMain, KeyBenifitWrapper } from '../styles/Hirepages.style'
import KeyBenifit from '../components/AndroidApp/KeyBenifit'
import TitleSection from '../components/TitleSection'

export const KeyBenifitSection = () => {
  return (
    <>
      <KeyBenifitMain>
        <Container>
          <TitleSection
            title={'Key Benefits'}
            content={`Perks you will get with our 
             Android development services`}
          />
          <Row>
            {KeyBenifitList?.map((item, index) => {
              return (
                <Col lg={4} md={6}>
                  <KeyBenifit
                    icons={item.img}
                    title={item.Title}
                    text={item.Content}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </KeyBenifitMain>
    </>
  )
}
export default KeyBenifitSection
