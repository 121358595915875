import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContainerLeft } from "../styles/globalStyles";
import { DevelopmentCon, DevlopmentWrapper } from "../styles/Hirepages.style";
import BannerTitle from "../comman/BannerTitle";
import DevelopmentLeft from "../components/AndroidApp/DevelopmentLeft";
import DevelopmentRight from "../components/AndroidApp/DevelopmentRight";

export const HireDevelopmentSection = () => {
  return (
    <DevlopmentWrapper>
      <Container>
        <BannerTitle
          IsSectionTitle
          MainTitle={"1000+ Android Apps Deployed On Google Play Store"}
        ></BannerTitle>
      </Container>
      <DevelopmentCon>
        <ContainerLeft>
          <Row className="g-0 align-items-center">
            <Col lg={6} className="development_li">
              <DevelopmentLeft/>
            </Col>
            <Col lg={6} className="development_li">
              <DevelopmentRight/>
            </Col>
          </Row>
        </ContainerLeft>
      </DevelopmentCon>
    </DevlopmentWrapper>
  );
};
export default HireDevelopmentSection;
