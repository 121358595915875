import React from 'react'
import Images from '../../assets/Images'
import { BannerImage } from '../../styles/Hirepages.style'

export const RightSide = () => {
  return (
    <BannerImage className='banner-small'>
      <img src={Images.IOSServiceBanner} className="img-fluid" />
    </BannerImage>
  )
}
export default RightSide