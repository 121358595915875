import React from "react";
import BannerTitle from "../../comman/BannerTitle";
import MainButton from "../../comman/Button";
import { BannerLeftCon } from "../../styles/Hirepages.style";

export const LeftSide = () => {
  return (
    <BannerLeftCon>
      <BannerTitle
        IsSectionTitle
        isSmallText
        MainTitle={<h1>Top iOS App <br/> Development <br/> Company In India.</h1>}
        Content={"We build modern, engaging, and robust iOS/iPhone applications to make your business portable on your smartphone"}
      ></BannerTitle>

      <MainButton isTitle={true} title={"Get Free Quote"} />
    </BannerLeftCon>
  );
};
export default LeftSide;
