import React from 'react'
import { DevelopmentData } from '../../services/data';
import { DevelopmentLeftCon, DevelopmentList } from '../../styles/Hirepages.style';

export const DevelopmentLeft = () => {
  return (
    <DevelopmentLeftCon>
         {DevelopmentData?.map((item) => {
              return (
                <DevelopmentList className='d-flex align-items-center'>
                  <div className="icons">{item.Image}</div>
                  <div className='devlopmet-con'>
                    <h5>{item.Title}</h5>
                    <p>{item.Content}</p>
                  </div>
                </DevelopmentList>
              );
            })}
    </DevelopmentLeftCon>
  )
}
export default DevelopmentLeft