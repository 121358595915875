import React from "react";
import { Container } from "react-bootstrap";
import ExportContent from "./ExportContent";
import { ExportWrapper } from "../styles/Hirepages.style";

export const ExportSection = () => {
  return (
    <ExportWrapper>
      <Container>
      <ExportContent
        MainTitle={"Looking to hire a Android App Developers in India?"}
        content={
          "Discuss your project with our expert team of Android app developers in India. Start planning and executing your project without wasting any time."

        }
      />
      </Container>
    </ExportWrapper>
  );
};
export default ExportSection;
