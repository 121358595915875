import React from 'react'
import { ButtonGropCon, CopyRightFooter, FooterBottom, FooterCon, FooterInclude, FooterModdle, FooterTextWrapper, FooterTop, FooterWrapper } from '../styles/Footer.style'
import {
  Contactdetails,
  Heading,
  Icons,
  SocialMedia,
  Text,
} from '../services/data'
import { Link } from 'react-router-dom'
import Images from '../assets/Images'
import { ButtonGroup, Col, Container, Row } from 'react-bootstrap'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterCon>
        <Container>
          <FooterTop>
            <Row className="align-items-center row_ul">
              <Col lg={4}>
                <h3 className="wow fadeInUp">Join Our Newsletter Now!</h3>
              </Col>
              <Col lg={4} md={6} className="row_li">
                <ButtonGropCon>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    className="email_input"
                  />
                  <input
                    type="submit"
                    name=""
                    defaultValue="Signup"
                    className="signup_btn"
                  />
                </ButtonGropCon>
              </Col>
              <Col lg={4} md={6} className="row_li">
                <ul className="socials_icon justify-content-end wow fadeInUp">
                  {Icons?.map((icon, index) => {
                    console.log(icon, 'Item')
                    return (
                      <li>
                        <Link to={icon.path}>
                          <i>{icon.name}</i>
                        </Link>
                        <p>{icon.images} </p>
                      </li>
                    )
                  })}
                </ul>
              </Col>
            </Row>
          </FooterTop>
          <FooterModdle>
            <Row>
              <Col lg={3} md={12} sm={6} className="footer_image_li">
                <div className="footer_include footer_image wow fadeInDown">
                  <Link to={'/'}>
                    <img src={Images.SecondaryLogo} className="img-fluid" />
                  </Link>
                </div>
              </Col>
              {Text.map((item, index) => {
                return (
                  <Col lg={3} md={4} sm={6} className="footer_li">
                    <FooterInclude className='footer_include'>
                      <div className="f_block d-flex align-items-start">
                        <i> {item.icon}</i>
                        <div className="f_text">
                          <h5>{item.name}</h5>
                          <p>
                            <Link to={item.path}>{item.title}</Link>
                          </p>
                        </div>
                      </div>
                    </FooterInclude>
                  </Col>
                )
              })}
            </Row>
          </FooterModdle>
          <FooterBottom>
            <Row>
              <Col lg={3} md={6} sm={6}>
                <FooterTextWrapper>
                  <h5>Latest From Our Blog</h5>
                  {Heading.map((item, index) => {
                    return (
                      <ul className="blog_list_wrapper">
                        <li>
                          <p to={item.path} className="blog_title">
                            {item.content}
                          </p>
                          <span>{item.subContent}</span>
                        </li>
                      </ul>
                    )
                  })}
                </FooterTextWrapper>
              </Col>

              <Col lg={3} md={6} sm={6}>
                {Contactdetails.map((item, index) => {
                  return (
                    <FooterTextWrapper>
                      <h5>{item.title}</h5>
                      <div className="footer_contact_details">
                        <i> {item.icon}</i>
                        <p>
                          <Link to={item.path}>{item.content1}</Link>
                        </p>
                      </div>
                      <div></div>
                      <div className="footer_contact_details">
                        <i>{item.icon2}</i>
                        <p>
                          <Link to={item.path}>{item.content2}</Link>
                        </p>
                      </div>
                    </FooterTextWrapper>
                  )
                })}
              </Col>

              <Col lg={3} md={6} sm={6}>
                <FooterTextWrapper>
                  <h5>Sales Inquiry</h5>
                  <div className="footer_include">
                    {SocialMedia.map((item, index) => {
                      return (
                        <div className="f_block d-flex align-items-start">
                          <i> {item.icon}</i>
                          <div className="f_text">
                            <h3>{item.media}</h3>
                            <p>
                              <Link to={item.path}>{item.contact}</Link>
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </FooterTextWrapper>
              </Col>

              <Col lg={3} md={6}>
                <FooterTextWrapper>
                  <h5>Our Location</h5>
                  <div className="map_img mt-4">
                    <a
                      href="https://g.page/tecocraft-infusion-pvt-ltd?share"
                      target="_blank"
                    >
                      <img src={Images.MapLogo} className="img-fluid" />
                    </a>
                  </div>
                </FooterTextWrapper>
              </Col>
            </Row>
          </FooterBottom>

          <CopyRightFooter>
            <ul>
              <li>
                <a href="javascript:void(0);">About Us</a>
              </li>
              <li>
                <a href="javascript:void(0);">Blog</a>
              </li>
              <li>
                <a href="javascript:void(0);">FAQs</a>
              </li>
              <li>
                <a href="javascript:void(0);">Privacy Policy</a>
              </li>
              <li>
                <a href="javascript:void(0);">Terms &amp; Conditions</a>
              </li>
            </ul>
            <p className="wow fadeInDown">
              Copyright © 2021 by <Link to="/">Tecocraft</Link>
            </p>
          </CopyRightFooter>

        </Container>
      </FooterCon>
    </FooterWrapper>
  )
}

export default Footer
