import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BannerTitle from "../../comman/BannerTitle";
import MainButton from "../../comman/Button";
import LanguageSlider from "../../comman/LanguageSlider";
import {
    SliderCon,
  SliderContent,
  TechnologyWrapper,
  TitleWrapper,
} from "../../styles/Servicespages.style";

export const Technology = () => {
  return (
    <TechnologyWrapper>
      <Container>
        <TitleWrapper>
          <Row className="align-items-center">
            <Col lg={8}>
              <BannerTitle
                IsSectionTitle
                isSmallText
                MainTitle={"Technology Used by Us"}
                Content={
                  "The tools used by our expert iOS app developers have the potential to give quality output:"
                }
              />
            </Col>
            <Col lg={4} className="button-text text-end">
              <MainButton
                isBtnText={true}
                isPrimary
                isTitle={true}
                title={"Get In Touch"}
              />
            </Col>
          </Row>
        </TitleWrapper>
        <SliderContent>
          <LanguageSlider />
        </SliderContent>
      </Container>
    </TechnologyWrapper>
  );
};
export default Technology;
