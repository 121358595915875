import styled from "styled-components";

export const MegaMenu = styled.div`
  margin-top: 35px;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
  -moz-box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
  border-radius: 25px;
  padding: 35px !important;
  .list-menu {
    background: #f5f5f5;
    align-items: center;
    border-radius: 15px;
    padding: 7px 12px;
    width: auto;
    display: inline-flex;
    margin-top: 15px;
    &:hover{
      span{
        color: #dd3a31 !important;
      }
    }
    span {
      font-size: 14px !important;
      font-family: "PoppinsMedium";      
      color: #343a40 !important;
      text-decoration: none;
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #dee2e6 !important;
    p {
      font-size: 15px;
      line-height: 21px;
      font-weight: 400;
      font-family: "PoppinsMedium";
      margin-bottom: 0;
      color: #71788a;
      text-align: center;
      margin: 19px 0 0;
    }
  }
  &.first-menu {
    li {
      padding: 5px 0 0 15px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        background: #f0c001;
        position: absolute;
        left: 0;
        top: 15px;
        border-radius: 50%;
      }
    }
    .megamenu-li {
      &:nth-child(2) {
        h3 {
          &::before {
            background: #dd3a31 !important;
          }
        }
        .megamenu-list {
          li {
            ::before {
              background: #dd3a31 !important;
            }
          }
        }
      }
      &:nth-child(3) {
        h3 {
          &::before {
            background: #0098d1 !important;
          }
        }
        .megamenu-list {
          li {
            ::before {
              background: #0098d1 !important;
            }
          }
        }
      }
      &:nth-child(4) {
        h3 {
          &::before {
            background: #a4c43a !important;
          }
        }
        .megamenu-list {
          li {
            ::before {
              background: #a4c43a !important;
            }
          }
        }
      }
      h3 {
        font-size: 17px !important;
        font-family: "PoppinsBold";
        position: relative;
        padding-bottom: 20px;
        &::before {
          content: "";
          width: 70px;
          background: #fbc901;
          display: block;
          height: 4px;
          margin-top: 5px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    h3 {
      position: relative;
      &::before {
        content: "";
        width: 70px;
        background: #fbc901;
        display: block;
        height: 4px;
        margin-top: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  h3 {
    font-size: 17px !important;
    font-family: "PoppinsBold";
    position: relative;
    padding-bottom: 20px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
    background-color: transparent;
    border: 0;
    padding: 20px !important;
    box-shadow: unset;
    .megamenu-li {
      margin-bottom: 23px;
    }
    &.first-menu {
      border-radius: 0;
    }
  }
`;

export const MegaMenuMain = styled.div`
  position: absolute;
  top: 82px;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;

  /* @media (max-width: 991px) {
    opacity: 1;
    visibility: visible;
    position: unset;
  } */
  @media (max-width: 991px) {
    opacity: 1;
    visibility: visible;
    position: unset;
    transform: unset;   
  }
`;
export const NavbarDiv = styled.div`
  padding: 0;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
  width: 100%;
  float: left;
  position: relative;
  padding-left: 25px;
  nav {
    margin: 0 auto;
    ul {
      display: flex;
      margin: 0;
      li {
        &:last-child,
        &:nth-child(5) {
          ${MegaMenuMain} {
            width: 100%;
            left: auto;
            right: auto;
            max-width: 210px;
            .megamenu-li {
              width: 100%;
              flex: 0 0 100%;
            }
            h3 {
              display: none;
            }
            ${MegaMenu} {
              padding: 15px !important;
            }
          }
        }
        &:hover {
          ${MegaMenuMain} {
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
          }
          .nav-link {
            .up-arrow {
              opacity: 1;
              @media (max-width: 991px) {
                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }
        .nav-link {
          font-family: "PoppinsMedium";
          font-size: 16px;
          color: #181825;
          padding: 31px 26px;
          line-height: 20px;
          position: relative;
          @media (max-width: 991px) {
            color: #fff;
          }
          .down-arrow {
            margin-left: 5px;
          }
          .up-arrow {
            position: absolute;
            top: 82px;
            left: 0;
            right: 0;
            margin: auto !important;
            display: block;
            opacity: 0;
            -webkit-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
          }
          img {
            margin-left: 5px;
          }
          &:hover {
            color: #dd3a31;
          }
        }
      }
    }
  }
  .header_btn {
    padding: 30px 30px;
    background-color: #dd3a31;
    border: none;
    border-radius: 0 24px 24px 0;
    font-size: 15px;
    margin-right: -1px;
    color: #fff;
    font-family: "PoppinsMedium";
  }
  @media (max-width: 1600px) {
    nav {
      ul {
        li {
          .nav-link {
            padding: 12px 11px;
          }
        }
      }
    }
    .header_btn {
      padding: 30px 12px;
      font-size: 14px;
    }
  }
  @media (max-width: 1400px) {
    padding-left: 15px;
    nav {
      ul {
        li {
          .nav-link {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media (max-width: 1199px) {
    > a {
      img {
        width: 86px;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 12px;
    border-radius: 15px;
    margin-top: 20px;
    nav {
      position: fixed;
      top: 0;
      z-index: 20000;
      background: #000;
      width: 350px;
      bottom: 0;
      transition: all 0.5s ease;
      left: -350px;
      overflow-y: auto;
      ul {
        padding-top: 32px;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        li {
          width: 100%;
          .nav-link {
            color: #fff;
            border-top: 1px solid #ffffff59;
            padding: 9px 14px !important;
            display: flex;
            font-size: 15px;

            img {
              margin-left: auto;
            }
          }
        }
      }
      &.active {
        left: 0;
      }
    }
    .header_btn {
      border-radius: 50px;
      display: inline-block;
      padding: 11px 22px;
      margin-top: 0;
      font-size: 12px;
      margin-right: 10px;
      margin-left: auto;
    }
  }
  @media (max-width: 480px) {
    nav {
      width: 250px;
      left: -250px;
    }
    .header_btn {
      border-radius: 30px;
      padding: 4px 6px;
      font-size: 10px;
      margin-right: 10px;
    }
  }
`;

export const HeaderWrapper = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 30px;
  &.affix {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
    z-index: 99;
    ${NavbarDiv} {
      background: transparent;
      border-radius: 0 !important;
      box-shadow: unset;
      .header_btn {
        border-radius: 30px !important;
        padding: 10px 14px;
      }
    }
  }
  @media (max-width: 1600px) {
    padding: 15px 0;
    &.affix {
      ${NavbarDiv} {
        .header_btn {
          padding: 7px 9px;
        }
        .up-arrow {
          top: 60px;
        }
        ${MegaMenuMain} {
          top: 60px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding: 0;
  }
`;
export const MegamenuSub = styled.div`
  margin-top: 10px;
  li {
    padding: 5px 0 5px 0;
    position: relative;
    a {
      font-size: 14px !important;
      font-family: "PoppinsMedium";
      display: flex;
      align-items: center;
      color: #343a40 !important;
      text-decoration: none;
      font-size: 13px;
      &:hover {
        color: #dd3a31 !important;
      }
      img {
        margin-right: 10px;
        width: 35px;
        &.hover_img {
          display: none;
        }
      }
      &:hover {
        img {
          &.hover_img {
            display: block;
          }
          &.main_img {
            display: none;
          }
        }
      }
    }
  }
`;

export const HeaderMain = styled.div``;
export const Togglebutton = styled.div`
  display: none;
  .icon-bar {
    width: 21px;
    height: 2px;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-top: 4px;
    background-color: #dd3a31;
    &.middle-bar {
      background-color: #fbc901;
    }
    &.bottom-bar {
      background-color: #dd3a31;
    }
  }
  @media (max-width: 991px) {
    display: block;
  }
`;
export const HeaderDiv = styled.div``;
