import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Hiredata } from "../services/data";
import { ContainerLeft } from "../styles/globalStyles";
import {
  CardIcon,
  HireBlockInside,
  HireWrapper,
  MainCardWrp,
  ServiceBlock,
} from "../styles/Hirepages.style";
import HireLeft from "../components/AndroidApp/HireLeft";
import HireRight from "../components/AndroidApp/HireRight";


export const HireSection = () => {
  return (
    <HireWrapper>
      <ContainerLeft>
        <Row className="g-0 align-items-center">
          <Col lg={6} className="banner_li">
            <HireLeft />
          </Col>
          <Col lg={6} className="banner_li">
            <HireRight />
          </Col>
        </Row>
      </ContainerLeft>
      <Container>
        <ServiceBlock>
          <Row className="align-items-center">
            {Hiredata?.map((item) => {
              return (
                <Col lg={6} md={6} className="service_block">
                  <MainCardWrp>
                    <CardIcon>{item.Icon}</CardIcon>
                    <div class="hire_block_desc">
                      <p>{item.Text}</p>
                    </div>
                  </MainCardWrp>
                </Col>
              );
            })}            
          </Row>
        </ServiceBlock>
      </Container>
    </HireWrapper>
  );
};
export default HireSection;
