import React from "react";
import { Container } from "react-bootstrap";
import { IconsDiv, KeyBenifitWrapper } from "../../styles/Hirepages.style";

export const KeyBenifit = ({ icons, title, text }) => {
  return (
    <>
        <KeyBenifitWrapper>
          <div className="amfeatures_block">
            <div className="amfeatures_title">
              <IconsDiv>{icons}</IconsDiv>
              <h4>{title}</h4>
            </div>
            <p>{text}</p>
          </div>
        </KeyBenifitWrapper>
    </>
  );
};
export default KeyBenifit;
