import React from 'react'
import AppBannerImage from '../../assets/Service_pages_images/App-Banner.png'
import { BannerImageWrapper } from '../../styles/Servicespages.style'
import IOSChooesImg from '../../assets/Service_pages_images/Service-Inside4.png'

export const AppDevelopmentRight = ({ isSection }) => {
  return (
    <>
      {isSection === true ? (
        <BannerImageWrapper>
          <img src={IOSChooesImg} className="img-fluid" />
        </BannerImageWrapper>
      ) : (
        <BannerImageWrapper>
          <img src={AppBannerImage} className="img-fluid" />
        </BannerImageWrapper>
      )}
    </>
  )
}
export default AppDevelopmentRight
