import React from "react";
import { Col, Row } from "react-bootstrap";
import { ContainerLeft } from "../../styles/globalStyles";
import { IosDevelopmentWrapper } from "../../styles/Servicespages.style";
import AppDevelopmentLeft from "./AppDevelopmentLeft";
import AppDevelopmentRight from "./AppDevelopmentRight";

export const ChooseServices = () => {
  return (
    <IosDevelopmentWrapper>
    <ContainerLeft>
      <Row className="g-0 align-items-center">
        <Col lg={6} className="development_li">
          <AppDevelopmentLeft isSection={true}/>
        </Col>
        <Col lg={6} className="development_li">
          <AppDevelopmentRight isSection={true}/>
        </Col>
      </Row>
    </ContainerLeft>
    </IosDevelopmentWrapper>
  );
};
export default ChooseServices;
