import React from 'react'
import BlogImage from '../../assets/Service_pages_images/Blog-Image.png'
import { BlogImages } from '../../styles/Hirepages.style'

export const BlogLeft = () => {
  return (
    <BlogImages>
      <img src={BlogImage} className="img-fluid" />
    </BlogImages>
  )
}
export default BlogLeft
