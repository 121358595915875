import React from "react";
import BannerTitle from "../../comman/BannerTitle";
import { BannerLeftCon } from "../../styles/Hirepages.style";

export const BlogRight = () => {
  return (
    <BannerLeftCon className="blog-content">
      <BannerTitle
        IsSectionTitle
        isSmallText
        MainTitle={<h2>Hire Android App Developers <br/>In India: Dedicated Resources</h2>}
      ></BannerTitle>      
      <p>
        Enlarge your android developer team virtually by hiring our remote
        Android app developers in India for your projects. Tecocraft provides
        dedicated resources to hire remote android developers. We have flexible
        pricing and working hours that are dedicated to your project.
      </p>
      <p>
        In work spike or seasonal, projects it is not recommended to hire an
        android programmer in-house. Offshore resource hiring is the best
        solution for that. You can get high qualified expert android developers
        in India to work on your project on your time remotely without investing
        in any resources. Just pay the hourly rates, and our mobile app
        developers will work on your instruction. You can manage the team
        virtually with skype and slake smoothly and get your work done on time.
      </p>
      <p>
        Our Android app developers adopt new technologies and tools to serve
        robust yet scalable solutions as per your project needs. Our android
        development team has worked with many development companies delivering
        high-quality code each time without any communication gap and quality
        issues.
      </p>
    </BannerLeftCon>
  );
};
export default BlogRight;
