import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Images from '../../assets/Images'
import { ContainerLeft } from '../../styles/globalStyles'
import { BannerleftWrapper } from '../../styles/Hirepages.style'
import LeftSide from './LeftSide'
import RightSide from './RightSide'

export const IOSBannerSection = () => {
  return (
    <BannerleftWrapper>
    <img src={Images.LeftShape} className="left-shape-images img-fluid" />
    <ContainerLeft>
      <Row className="g-0 align-items-center">
        <Col lg={6} className="banner_li">
          <LeftSide/>
        </Col>
        <Col lg={6} className="banner_li">
          <RightSide/>
        </Col>
      </Row>
    </ContainerLeft>
    </BannerleftWrapper>
  )
}
export default IOSBannerSection