import React, { useEffect, useState } from 'react'
import { Container, Row, Col, NavLink, Offcanvas } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { MenuData } from '../services/data'
import {
  HeaderWrapper,
  HeaderMain,
  NavbarDiv,
  Togglebutton,
  HeaderDiv,
  MegaMenu,
  MegamenuSub,
  MegaMenuMain,
} from '../styles/Header.style'
import Images from '../assets/Images'
import { ReactComponent as Uxicon } from '../assets/Header/UI_UX_Design_Development.svg'

const Header = (props, to) => {
  // const { active, setActive } = props;
  const history = useNavigate();
  const [active, setActive] = React.useState(false);
  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50)
    })
  }, [])

  // const optionRemove = () => {
  //   document
  //     .getElementsByTagName('html')[0]
  //     .removeAttribute('class', `.Noscroll`)
  //   setActive(!active)
  // }
  // const optionAdd = () => {
  //   document.getElementsByTagName('html')[0].setAttribute('class', `Noscroll`)
  //   setActive(!active)
  // }

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {/* <header className="affix"> */}
      <HeaderWrapper className={scroll ? 'affix' : 'header-classic'}>
        <HeaderDiv>
          <Container>
            <NavbarDiv className="d-flex align-items-center">
              <Link to="/">
                <img src={Images.PrimaryLogo} className="img-fluid" />
                {/* TecoCraft Logo */}
              </Link>
              {show ? (
                <Offcanvas show={show} onHide={handleClose} {...props}>
                  <nav className={active ? `nav m-auto active` : `nav`}>
                    <ul>
                      {MenuData?.map((item, index) => {
                        return item.menuDetail === true ? (
                          <li>
                            {/* Services */}

                            <NavLink
                              to={item.path}
                              onClick={() => setIsSubMenuShow(!isSubMenuShow)}
                            >
                              {item.name}
                              <img
                                src={Images.DownArrow}
                                className="down-arrow"
                              />
                              <img src={Images.UpArrow} className="up-arrow" />
                            </NavLink>
                            {isSubMenuShow && (
                              <>
                                <MegaMenuMain
                                  className={toggle ? "menu-active" : null}
                                >
                                  <MegaMenu className="first-menu">
                                    <Row>
                                      {item?.InnnerMenu?.map(
                                        (SubMenu, index) => {
                                          return (
                                            <Col lg={3} className="megamenu-li">
                                              <h3>{SubMenu.title}</h3>

                                              <MegamenuSub className="megamenu-list yellow list-col-4">
                                                {SubMenu?.InnerItem?.map(
                                                  (InnerItemMenu, index) => {
                                                    console.log(
                                                      "===================================="
                                                    );
                                                    console.log(
                                                      "InnerItemMenu",
                                                      InnerItemMenu
                                                    );
                                                    console.log(
                                                      "===================================="
                                                    );
                                                    return (
                                                      <li>
                                                        <Link
                                                          to={
                                                            InnerItemMenu.path
                                                          }
                                                        >
                                                          {InnerItemMenu.name}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                                {/* {InnnerMenu.isMenu === true ? (
                                                    <div>                                                     
                                                     UX Ui development
                                                    </div>
                                                  ) : null} */}
                                              </MegamenuSub>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </MegaMenu>
                                </MegaMenuMain>
                              </>
                            )}
                          </li>
                        ) : item.details === true ? (
                          <li>
                            <NavLink
                              onClick={(e) => setIsSubMenuShow(!isSubMenuShow)}
                            >
                              {/* Hire Resources */}
                              {item.name}
                              <img
                                src={Images.DownArrow}
                                onClick={() => setToggle(!toggle)}
                                className="down-arrow"
                              />
                              <img src={Images.UpArrow} className="up-arrow" />
                            </NavLink>
                            {isSubMenuShow && (
                              <>
                                <MegaMenuMain
                                  className={toggle ? "menu-active" : null}
                                >
                                  <MegaMenu>
                                    <Row>
                                      {item?.InnnerMenu?.map(
                                        (SubMenu, index) => {
                                          return (
                                            <div
                                              className={
                                                item.isSolution === true
                                                  ? "megamenu-li col-lg-4"
                                                  : "megamenu-li col-lg-3"
                                              }
                                            >
                                              <h3>{SubMenu.title}</h3>
                                              <MegamenuSub className="megamenu-list yellow list-col-4">
                                                {SubMenu?.InnerItem?.map(
                                                  (InnerItemMenu, index) => {
                                                    return (
                                                      <li key={index}>
                                                        <Link
                                                          to={
                                                            InnerItemMenu.path
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              InnerItemMenu.image1
                                                            }
                                                            className="main_img"
                                                          />
                                                          <img
                                                            src={
                                                              InnerItemMenu.image2
                                                            }
                                                            className="hover_img"
                                                          />
                                                          {InnerItemMenu.name}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </MegamenuSub>
                                            </div>
                                          );
                                        }
                                      )}
                                      {item.isLogo === true ? (
                                        <div
                                          className={
                                            item.isSolution === true
                                              ? "logo-content col-lg-4"
                                              : "logo-content col-lg-3"
                                          }
                                        >
                                          <div className="header_bottom industry_menu">
                                            <div className="header_logo text-center">
                                              <img
                                                src={Images.PrimaryLogo}
                                                className="img-fluid"
                                              />
                                            </div>
                                          </div>
                                          {item.isSiteInfo === true ? (
                                            <div className="site_info">
                                              <p>
                                                Tecocraft Ltd has been
                                                empowering for creating custom
                                                software applications and
                                                solutions for customers willing
                                                to grow their business and want
                                                to provide a great user
                                                experience to end users.
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </Row>
                                  </MegaMenu>
                                </MegaMenuMain>
                              </>
                            )}
                          </li>
                        ) : null
                      })}
                    </ul>
                  </nav>
                </Offcanvas>
              ) : (
                <nav className={active ? `nav m-auto active` : `nav`}>
                  <ul>
                    {MenuData?.map((item, index) => {
                      return item.menuDetail === true ? (
                        <li onClick={() => setToggle(!toggle)}>
                          {/* Services */}
                          <NavLink to={item.path}>
                            {item.name}
                            <img src={Images.DownArrow} />
                            <img src={Images.UpArrow} className="up-arrow" />
                          </NavLink>
                          <MegaMenuMain
                            className={toggle ? 'menu-active' : null}
                          >
                            <MegaMenu className="first-menu">
                              <Row>
                                {item?.InnnerMenu?.map((SubMenu, index) => {
                                  console.log("InnnerMenu", SubMenu.isMenu);
                                  return (
                                    <Col lg={3} className="megamenu-li">
                                      <h3>{SubMenu.title}</h3>
                                      <MegamenuSub className="megamenu-list yellow list-col-4">
                                        {SubMenu?.InnerItem?.map(
                                          (InnerItemMenu, index) => {
                                            return (
                                              <li>
                                                <Link to={InnerItemMenu.path}>
                                                  {InnerItemMenu.name}
                                                </Link>
                                              </li>
                                            )
                                          },
                                        )}
                                        {item.isMenu === true ? (
                                          <li>
                                            <Link to={'/'}>
                                              <div>{Uxicon}</div>
                                              UX UI designer
                                            </Link>
                                          </li>
                                        ) : null}
                                      </MegamenuSub>
                                      {SubMenu.isMenu && (
                                        <>
                                          {SubMenu.isMenuUxUI === true ? (
                                            <div className="list-menu">
                                              <Link
                                                to={"/"}
                                                className="d-flex align-items-center"
                                              >
                                                <div>
                                                  <Uxicon />
                                                </div>
                                                <span>
                                                  UI/UX Design & Development
                                                </span>
                                              </Link>
                                            </div>
                                          ) : (
                                            <div className="list-menu">
                                              <Link
                                                to={"/"}
                                                className="d-flex align-items-center"
                                              >
                                                <div>
                                                  <Uxicon />
                                                </div>
                                                <span>
                                                  Testing & Quality Assurance
                                                </span>
                                              </Link>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  )
                                })}
                              </Row>
                            </MegaMenu>
                          </MegaMenuMain>
                        </li>
                      ) : item.details === true ? (
                        <li onClick={() => setToggle(!toggle)}>
                          <NavLink>
                            {/* Hire Resources */}
                            {item.name}
                            <img
                              src={Images.DownArrow}
                              onClick={() => setToggle(!toggle)}
                            />
                            <img src={Images.UpArrow} className="up-arrow" />
                          </NavLink>
                          <MegaMenuMain
                            className={toggle ? 'menu-active' : null}
                          >
                            <MegaMenu>
                              <Row>
                                {item?.InnnerMenu?.map((SubMenu, index) => {
                                  return (
                                    <div
                                      className={
                                        item.isSolution === true
                                          ? 'megamenu-li col-lg-4'
                                          : 'megamenu-li col-lg-3'
                                      }
                                    >
                                      <h3>{SubMenu.title}</h3>
                                      <MegamenuSub className="megamenu-list yellow list-col-4">
                                        {SubMenu?.InnerItem?.map(
                                          (InnerItemMenu, index) => {
                                            return (
                                              <li key={index}>
                                                <Link to={InnerItemMenu.path}>
                                                  <img
                                                    src={InnerItemMenu.image1}
                                                    className="main_img"
                                                  />
                                                  <img
                                                    src={InnerItemMenu.image2}
                                                    className="hover_img"
                                                  />
                                                  {InnerItemMenu.name}
                                                </Link>
                                              </li>
                                            )
                                          },
                                        )}
                                      </MegamenuSub>
                                    </div>
                                  )
                                })}

                                {item.isLogo === true ? (
                                  <div
                                    className={
                                      item.isSolution === true
                                        ? 'logo-content col-lg-4'
                                        : 'logo-content col-lg-3'
                                    }
                                  >
                                    <div className="header_bottom industry_menu">
                                      <div className="header_logo text-center">
                                        <img
                                          src={Images.PrimaryLogo}
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                    {item.isSiteInfo === true ? (
                                      <div className="site_info">
                                        <p>
                                          Tecocraft Ltd has been empowering for
                                          creating custom software applications
                                          and solutions for customers willing to
                                          grow their business and want to
                                          provide a great user experience to end
                                          users.
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </Row>
                            </MegaMenu>
                          </MegaMenuMain>
                        </li>
                      ) : null
                    })}
                  </ul>
                </nav>
              )}
              <Link
                to="/"
                className="btn btn-brand btn-rounded btn-sm header_btn"
              >
                Estimate Your Project
              </Link>
              <Togglebutton onClick={handleShow}>
                <span className="icon-bar top-bar mt-0" />
                <span className="icon-bar middle-bar" />
                <span className="icon-bar bottom-bar" />
              </Togglebutton>
            </NavbarDiv>
          </Container>
        </HeaderDiv>
      </HeaderWrapper>
    </>
  )
}

export default Header
