import React from "react";
import HireFlowSection from "../comman/HireFlowSection";
import BannerSection from "../comman/BannerSection";
import BlogSection from "../comman/BlogSection";
import ExportSection from "../comman/ExportSection";
import HireSection from "../comman/HireSection";
import KeyBenifitSection from "../comman/KeyBenifitSection";
import TalkExpertSection from "../comman/TalkExpertSection";
import Layout from "../containers/Layout";
import HireServiceSection from "../comman/HireServiceSection";
import HireDevelopmentSection from "../comman/HireDevelopmentSection";
import AndroidExportSection from "../components/AndroidApp/AndroidExportSection";
import FaqSection from "../comman/FaqSection";

export const AndroidApp = () => {
  return (
    <Layout>
        <BannerSection/>
        <BlogSection/>
        <ExportSection/>
        <HireSection/>
        <HireFlowSection/>
        <TalkExpertSection/>
        <KeyBenifitSection/>
        <HireServiceSection/>
        <HireDevelopmentSection/>
        <AndroidExportSection/>
        <FaqSection/>
    </Layout>
  );
};

export default AndroidApp;
