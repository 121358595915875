import React from 'react'
import BannerTitle from '../../comman/BannerTitle'
import { BannerLeftCon } from '../../styles/Hirepages.style'
import {
  BannerImageWrapper,
  IosDevelopmentWrapper,
} from '../../styles/Servicespages.style'
import OriginImage from '../../assets/Service_pages_images/Image-2.png'
import { Col, Row } from 'react-bootstrap'
import { ContainerLeft } from '../../styles/globalStyles'

export const OriginServices = () => {
  return (
    <IosDevelopmentWrapper>
      <ContainerLeft>
        <Row className="g-0 align-items-center">
          <Col lg={6} className="development_li">
            <BannerLeftCon className="p-0">
              <BannerTitle
                IsSectionTitle
                isSmallText
                MainTitle={
                  <h2>
                    Origin Of iOS App <br /> Development
                  </h2>
                }
              ></BannerTitle>
              <p>
                In 2008, with the launch of the App Store, iPhone users were
                able to download and install different apps on their devices.
                Apple enabled the usage of iTunes accounts to download the apps.
                And the same iTunes account was used by iPhone users to download
                songs on the computer. Eventually, many features were enabled
                through Microsoft Exchange Support such as calendars, push
                emails, contacts, and Apple’s MobileMe
              </p>
            </BannerLeftCon>
          </Col>
          <Col lg={6} className="development_li">
            <BannerImageWrapper>
              <img src={OriginImage} className="img-fluid" />
            </BannerImageWrapper>
          </Col>
        </Row>
      </ContainerLeft>
    </IosDevelopmentWrapper>
  )
}
export default OriginServices
