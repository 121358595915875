import React from "react";
import ClientTestomonial from "../comman/ClientTestomonial";
import DevelopmentProcess from "../comman/DevelopmentProcess";
import HireServiceSection from "../comman/HireServiceSection";
import TalkExpertSection from "../comman/TalkExpertSection";
import AppDevelopment from "../components/IOSServiceApp/AppDevelopment";
import AppService from "../components/IOSServiceApp/AppService";
import ChooseServices from "../components/IOSServiceApp/ChooseServices";
import ExportSection from "../components/IOSServiceApp/ExportSection";
import Industries from "../components/IOSServiceApp/Industries";
import IOSBannerSection from "../components/IOSServiceApp/IOSBannerSection";
import IOSBlogSection from "../components/IOSServiceApp/IOSBlogSection";
import IOSServiceFaq from "../components/IOSServiceApp/IOSServiceFaq";
import OriginServices from "../components/IOSServiceApp/OriginServices";
import ServiceBenifit from "../components/IOSServiceApp/ServiceBenifit";
import Technology from "../components/IOSServiceApp/Technology";
import Layout from "../containers/Layout";
import { Section } from "../styles/globalStyles";

export const IOSServiceApp = () => {
  return (
    <Layout>
      <Section>
        <IOSBannerSection />
      </Section>
      <Section>
        <IOSBlogSection />
      </Section>
      <Section>
        <AppDevelopment />
      </Section>
      <Section>
        <ServiceBenifit />
      </Section>
      <Section>
        <AppService/>
      </Section>
      <Section>
        <ExportSection />
      </Section>
      <Section>
        <ChooseServices />
      </Section>
      <Section>
        <DevelopmentProcess />
      </Section>
      <Section>
        <TalkExpertSection isService={true} />
      </Section>
      <Section>
        <OriginServices/>
      </Section>
      <Section>
        <Industries/>
      </Section>
      <Section>
        <Technology/>
      </Section>      
      <Section>
        <AppService isSection={true}/>
      </Section>
      <Section>
        <ClientTestomonial/>
      </Section>
      <Section>
        <TalkExpertSection isService={true} />
      </Section>
      <Section>
        <IOSServiceFaq/>
      </Section>
     
    </Layout>
  );
};
export default IOSServiceApp;
