import React from "react";
import { Container } from "react-bootstrap";
import ExportContent from "../../comman/ExportContent";
import { ExportWrapper } from "../../styles/Hirepages.style";

export const ExportSection = () => {
  return (
    <ExportWrapper className="service_export_wrapper">
      <Container>
        <ExportContent
          isSection={true}
          MainTitle={
            "Would you like to talk to our experts about your project?"
          }
        />
      </Container>
    </ExportWrapper>
  );
};
export default ExportSection;
