import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { Siderdata, SiderServicedata } from '../services/data'
import { SliderWrapper, SwiperArrow } from '../styles/Servicespages.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const LanguageSlider = ({ isServiceSlide }) => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <>
      {isServiceSlide == true ? (
        <SliderWrapper className="service_slide">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            modules={[Navigation, Autoplay]}
            Autoplay={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              575: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="mySwiper language_slider"
          >
            {SiderServicedata?.map((item, index) => {
              return (
                <SwiperSlide key={index + 1}>
                  <div class="tech_block red">
                    <img src={item.Icon} class="img-fluid" />
                    <h4>{item.Text}</h4>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <SwiperArrow>
            <div className="overlay-btn prev">
              <button ref={navigationPrevRef}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>
            <div className="overlay-btn next">
              <button ref={navigationNextRef}>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </SwiperArrow>
        </SliderWrapper>
      ) : (
        <SliderWrapper>
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            navigation={false}
            modules={[Navigation, Autoplay]}
            Autoplay={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              575: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="mySwiper language_slider"
          >
            {Siderdata?.map((item, index) => {
              return (
                <SwiperSlide key={index + 1}>
                  <div class="tech_block red">
                    <img src={item.Icon} class="img-fluid" />
                    <h4>{item.Text}</h4>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SliderWrapper>
      )}
    </>
  )
}
export default LanguageSlider
