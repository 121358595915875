import React from 'react'
import BannerIamge from '../../assets/Hire_pages_images/Android-Resource-Image.png'
import { BlogImages } from '../../styles/Hirepages.style'

export const BlogLeft = () => {
  return (
    <BlogImages>
      <img src={BannerIamge} className="img-fluid" />
    </BlogImages>
  )
}
export default BlogLeft
