import React from "react";
import BannerTitle from "../../comman/BannerTitle";
import { BannerLeftCon } from "../../styles/Hirepages.style";

export const BlogRight = () => {
  return (
    <BannerLeftCon className="blog-content">
      <BannerTitle
        IsSectionTitle
        isSmallText
        MainTitle={<h2>Leading iOS/iPhone App <br/> Development Company <br/>in  India</h2>}
      ></BannerTitle>
      <p>
        Tecocraft is a world-leading iOS/iPhone app development in India.
        Providing sterling, feature-rich, and fully optimized iOS applications
        based on your specific business needs. With the launch of the new gadget
        by a tech leader, namely Apple Inc., the demand for iOS mobile
        applications is rapidly growing in the app market. It has created an
        opportunity for businesses to reach a global audience from any part of
        the globe with a feature-rich and fully customized mobile application.
      </p>
      <p>
        When it comes to iOS app development, Tecocraft is the most reputed
        iPhone/iOS app development agency. That provides the best-in-class iOS
        mobile applications for USA clients at affordable prices. At Tecocraft,
        we hold a team of experienced iOS app developers who have expertise in
        developing customer-centric, efficient. Fully functional apps to help
        your business reach its potential and capitalize on growth with improved
        efficiency and increased productivity.
      </p>
    </BannerLeftCon>
  );
};
export default BlogRight;
