import { Nav } from 'react-bootstrap'
import styled from 'styled-components'

import FooterBG from '../assets/Hire_pages_images/Footer-Bg.png'

export const FooterCon = styled.div`
  background-color: #222222;
  padding-top: 65px;
  background-image: url(../images/footer_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  z-index: 9;
  position: relative;
  h3 {
    color: #fff;
    font-weight: 700;
    font-size: 35px;
  }
  &::before {
    content: '';
    background: url(${FooterBG}) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100%;
    bottom: 0;
    z-index: -1;
  }
  @media (max-width: 1199px) {
    border-top-left-radius: 44px;
    border-top-right-radius: 44px;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
    &::before {
      background-size: cover;
    }
  }
  @media (max-width: 575px) {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    padding-top: 30px;
  }
`
export const FooterWrapper = styled.div``

export const ButtonGropCon = styled.div`
  position: relative;
  .email_input {
    background: #313131;
    border: none;
    padding: 15px 20px;
    border-radius: 50px;
    width: 100%;
    color: #fff;
    font-size: 15px;
    padding-right: 160px;
    transition: 0.3s;
  }
  .signup_btn {
    background: #dd3a31;
    border: none;
    padding: 7px 41px;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    position: absolute;
    right: 8px;
    top: 8px;
    transition: 0.3s;
    cursor: pointer;
  }
`
export const FooterTop = styled.div`
  .socials_icon {
    display: flex;
    margin-bottom: 0;
    li {
      margin: 0 5px;
      a {
        width: 40px;
        height: 40px;
        background: #fbc901;
        text-align: center;
        border-radius: 50px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #dd3a31;
        }
        i {
          color: #fff;
          font-size: 23px;
          display: flex;
        }
      }
    }
  }

  h3 {
    font-family: 'SeguiBold';
  }
  @media (max-width: 1600px) {
    h3 {
      font-size: 23px;
    }
    .socials_icon {
      li {
        a {
          width: 35px;
          height: 35px;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .row_li {
      padding-top: 30px;
    }
    .socials_icon {
      justify-content: center !important;
    }
  }
  @media (max-width: 575px) {
    h3 {
      font-size: 20px;
    }
  }
`
export const FooterModdle = styled.div`
  padding: 30px 0;
  border-top: 1px solid #3c3c3c;
  margin-top: 45px;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 45px;
  .footer_include {
    border-right: 1px solid #3c3c3c;
  }
  .footer_li {
    &:last-child {
      .footer_include {
        border-right: 0;
      }
    }
  }
  .footer_image {
    img {
      width: 40%;
    }
  }
  @media (max-width: 1600px) {
    padding: 30px 0;
    .footer_include {
      img {
        max-width: 60%;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 20px 0 0;
    margin: 27px 0;
    .footer_li {
      margin-bottom: 10px;
      width: 50%;
    }
    .footer_image_li {
      display: none;
    }
  }
  @media (max-width: 575px) {
    .footer_li {
      width: 100%;
    }
    .footer_include {
      border-right: 0;
    }
  }
`
export const FooterInclude = styled.div`
  .f_block {
    i {
      color: #fff;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 35px;
      background: #313131;
      text-align: center;
      font-size: 20px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .f_text {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    h5 {
      font-family: 'SeguiBold';
    }
    p {
      a {
        font-size: 15px;
        color: #a2a2a2;
        font-weight: 500;
        margin-bottom: 2px;
        display: block;
        line-height: 23px;
        font-family: 'PoppinsMedium';
      }
    }
  }
  @media (max-width: 1600px) {
    .f_text {
      h5 {
        font-size: 15px;
      }
      p {
        a {
          font-size: 12px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .f_block {
      i {
        width: 28px;
        height: 28px;
        display: block;
        line-height: 24px;
        font-size: 12px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
  }
`
export const FooterBottom = styled.div`
  a,
  p {
    font-size: 15px;
    color: #a2a2a2;
    font-weight: 500;
    margin-bottom: 2px;
    display: block;
    line-height: 23px;
    font-family: 'PoppinsRegular';
    &:hover {
      color: #fbc901;
    }
  }
  p {
    margin-bottom: 5px;
  }
`
export const FooterTextWrapper = styled.div`
  margin-bottom: 30px;
  h5 {
    padding-left: 15px !important;
    border-left: 2px solid #fbc901;
    padding: 0;
    margin-bottom: 25px;
    color: #fff;
    font-size: 20px;
    font-family: 'PoppinsBold';
  }
  .blog_list_wrapper {
    li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 20px;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #fac801;
        display: block;
        position: absolute;
        left: 0;
        border-radius: 50%;
        top: 10px;
      }
      .blog_title {
        color: #e1e1e1;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: normal;
        display: block;
        transition: 0.3s;
        font-family: 'PoppinsMedium';
      }
      span {
        color: #a2a2a2;
        font-family: 'PoppinsMedium';
      }
    }
  }
  .footer_contact_details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    i {
      color: #fff;
      width: 35px;
      height: 35px;
      display: block;
      line-height: 35px;
      background: #313131;
      text-align: center;
      font-size: 15px;
      border-radius: 50%;
      margin-right: 15px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      font-family: 'SeguiSemibold';
      margin-bottom: 0;
      color: #71788a;
    }
  }
  .f_block {
    margin-bottom: 30px;
    i {
      color: #fff;
      width: 35px;
      height: 35px;
      display: block;
      line-height: 35px;
      background: #313131;
      text-align: center;
      font-size: 15px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h3 {
      padding: 0 !important;
      border: none;
      margin-bottom: 0;
      padding-bottom: 5px !important;
      font-size: 20px;
    }
  }
  @media (max-width: 1600px) {
    .f_block {
      h3 {
        font-size: 15px;
      }
    }
    h5 {
      font-size: 15px;
    }
    .blog_list_wrapper {
      li {
        .blog_title {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    h5 {
      margin-bottom: 20px;
    }
    .f_block {
      margin-bottom: 16px;
      i {
        width: 28px;
        height: 28px;
        line-height: 24px;
        font-size: 12px;
        margin-right: 15px;
      }
    }
    .footer_contact_details {
      i {
        width: 28px;
        height: 28px;
        line-height: 24px;
        font-size: 12px;
        margin-right: 15px;
      }
    }
  }
`
export const CopyRightFooter = styled.div`
  border-top: 2px solid #3c3c3c;
  padding: 20px 0 30px 0;
  margin-top: 30px;
  display: flex;
  ul {
    margin: 0;
    display: flex;
    align-items: center;
    li {
      a {
        font-size: 15px;
        color: #a2a2a2;
        font-weight: 500;
        display: block;
        margin-right: 25px;
        font-family: 'PoppinsSemiBold';
      }
    }
  }
  p {
    margin-left: auto;
    display: flex;
    color: #a2a2a2;
    font-family: 'PoppinsSemiBold';
    font-size: 14px;
    a {
      margin-left: 5px;
      text-decoration: underline;
      color: #fff;
      font-family: 'PoppinsSemiBold';
      &:hover {
        color: #fbc901;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 20px 0;
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
    ul {
      li {
        a {
          font-size: 14px;
        }
      }
    }
    p {
      margin: auto;
      font-size: 14px;
    }
  }
  @media (max-width: 575px) {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      li {
        a {
        }
      }
    }
  }
`
