import React from "react";
import { Container } from "react-bootstrap";
import { ExportTitle, TalkToExport } from "../../styles/Hirepages.style";
import MainButton from "../../comman/Button";

export const TalkExpert = ({ Title, Text, isBtnText }) => {
  return (
    <TalkToExport>
      <Container>
        <ExportTitle>
          <div className="title">
            <h2>{Title}</h2>
            <em></em>
            <p>{Text}</p>
          </div>
          {isBtnText ==true? (
            <MainButton isPrimary isTitle={true} title={"Get In Touch"} />
          ):(
          <MainButton isPrimary isTitle={true} title={"Hire Now"} />
          )}
        </ExportTitle>
      </Container>
    </TalkToExport>
  );
};
export default TalkExpert;
