import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ServiceData } from "../services/data";
import { ContainerRight } from "../styles/globalStyles";
import { CardIcon, ServiceCardWrapper, ServiceCradLi, ServiceWrapper } from "../styles/Hirepages.style";
import ServiceLeft from "../components/AndroidApp/ServiceLeft";
import ServiceRight from "../components/AndroidApp/ServiceRight";

export const HireServiceSection = () => {
  return (
    <ServiceWrapper>
      <ContainerRight>
        <Row className="g-0 align-items-center">
          <Col lg={6} className="service_li">
            <ServiceLeft />
          </Col>
          <Col lg={6} className="service_li last">
            <ServiceRight />
          </Col>
        </Row>
      </ContainerRight>
      <Container>
        <ServiceCradLi>
            <Row className="align-items-center">
              {ServiceData?.map((item) => {
                return (
                  <Col lg={4} md={6} className="service_block">
                    <ServiceCardWrapper>
                      <div className="service-icon">{item.Icon}</div>
                        <h4>{item.content}</h4>
                    </ServiceCardWrapper>
                  </Col>
                );
              })}
            </Row>
        </ServiceCradLi>
      </Container>
    </ServiceWrapper>
  );
};
export default HireServiceSection;
