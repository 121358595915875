import React from "react";
import Images from "../assets/Images";
import { LeftText, SectionTitleWrp, WrapperContent } from "../styles/Hirepages.style";
import MainButton from "../comman/Button";

const ExportContent = ({ MainTitle, content,isSection }) => {
  return (
    <>
      {isSection === true ?(
         <WrapperContent className="d-block align-items-center onesection">
         <img src={Images.ExportShape}/>
         <LeftText>
           <h2>{MainTitle}</h2>          
         </LeftText>
         <MainButton isPrimary isTitle={true} title={"Get in touch"}></MainButton>
       </WrapperContent>
      ):(
      <WrapperContent className="d-flex align-items-center">
        <img src={Images.ExportShape}/>
        <LeftText>
          <h2>{MainTitle}</h2>
          <p>
            {content}          
          </p>
        </LeftText>
        <MainButton isPrimary isTitle={true} title={"Let’s Get Started"}></MainButton>
      </WrapperContent>
      )}
    </>
  );
};

export default ExportContent;
