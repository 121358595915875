import React from "react";
import Images from "../../assets/Images";
import { BannerImage } from "../../styles/Hirepages.style";

export const HireRight = () => {
  return (
    <BannerImage>
      <img src={Images.HireBanner} className="img-fluid" />
    </BannerImage>
  );
};
export default HireRight;
