import styled from "styled-components";
import Images from "../assets/Images";
import { BannerLeftCon, SectionTitleWrp } from "./Hirepages.style";

export const IosDevelopmentWrapper = styled.div`
  padding: 0 0 100px;

  @media (max-width: 1400px) {
    padding: 0 0 80px;
  }
  @media (max-width: 991px) {
    padding: 0 0 50px;
    ${BannerLeftCon} {
      &.max-50 {
        max-width: 720px;
        margin: auto;
        padding: 0 15px !important;
      }
    }
  }
  @media (max-width: 575px) {
    padding: 0 0 30px;
    ${BannerLeftCon} {
      &.max-50 {
        max-width: 540px;
      }
    }
  }
`;

export const BannerImageWrapper = styled.div`
  padding-left: 50px;
  img {
    margin-left: auto;
    display: block;
  }
`;

export const ServiceBenifitWrapper = styled.div``;

export const BenifitList = styled.div`
  margin: 30px 0 0;
  .beifit_li {
    position: relative;
    margin: 0 0 50px;
    padding: 10px 0;
    &:hover {
      span {
        &:after {
          width: 96%;
          border-radius: 10px;
          height: 182px;
        }
      }
    }
  }
  .benifit_img {
    span {
      height: 165px;
      width: 180px;
      display: block;
      background: #fff;
      box-shadow: 1px 6px 28px -2px rgb(202 202 202 / 75%);
      -webkit-box-shadow: 1px 6px 28px -2px rgb(202 202 202 / 75%);
      -moz-box-shadow: 1px 6px 28px -2px rgb(202 202 202 / 75%);
      border-radius: 10px;
      margin-right: 30px;
      &:after {
        content: "";
        position: absolute;
        transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
        width: 165px;
        height: 172px;
        background: #edf4f6;
        top: 14px;
        left: 9px;
        right: 0;
        z-index: -1;
        margin-right: auto;
        transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border-radius: 10px;
      }
    }
  }
  @media (max-width: 1600px) {
    .beifit_li {
      &:hover {
        span {
          &:after {
            height: 100%;
            width: 96%;
            top: 0;
          }
        }
      }
    }
    .benifit_img {
      span {
        &:after {
          top: 20px;
          left: 9px;
        }
      }
    }
  }
  @media (max-width: 1600px) {
    .beifit_li {
      &:hover {
        span {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .beifit_li {
      margin: 0 0 50px;
      padding: 0 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .benifit_img {
      span {
        height: 95px;
        width: 100px;
        &:after {
          content: "";
          display: none;
          width: 85px;
          height: 102px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .beifit_li {
      .flex-data {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .benifit_img {
      margin-bottom: 20px;
      span {
        margin: auto;
      }
    }
  }
`;
export const BenifitListRight = styled.div`
  max-width: 57%;
  h4 {
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

/*services section  css */
export const AppServiceWrapper = styled.div`
  position: relative;
  padding: 100px 0;
  &.hire_serive_sec {
    .service_li_image {
      padding-top: 300px;
    }
  }
  .service_li_image {
    padding-top: 150px;
  }
  &::before {
    content: "";
    background: url(${Images.ServiceBg}) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-size: 100%;
  }
  @media (max-width: 1199px) {
    &.hire_serive_sec {
      .service_li_image {
        padding-top: 150px;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 50px 0;
    .service_li_image {
      max-width: 720px;
      margin: auto;
      padding: 150px 15px 0;
    }
  }
  @media (max-width: 767px) {
    .service_li_image {
      padding: 100px 15px 0;
      max-width: 540px;
    }
    &.hire_serive_sec {
      .service_li_image {
        padding-top: 100px;
      }
    }
  }
  @media (max-width: 575px) {
    .service_li_image {
      padding: 71px 15px 0;
    }
    &.hire_serive_sec {
      .service_li_image {
        padding-top: 80px;
      }
    }
  }
`;
export const AppServiceimg = styled.div`
  padding-left: 50px;
  img {
    margin-left: auto;
    display: block;
  }
`;
export const ServiceContent = styled.div`
  .service_slider {
    margin: 70px 0 0;
    .slider_grid {
      padding-left: 0;
      .flex-div {
        margin-bottom: 60px;
        h4 {
          font-size: 18px;
          line-height: 1.5;
        }
      }
      &.right {
        .flex-div {
          justify-content: flex-end;
        }
        .svg-icon {
          margin-left: 30px;
        }
      }
    }
  }
  @media (max-width: 1600px) {
    .service_slider {
      .slider_grid {
        .flex-div {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 30px;
    .slider_grid {
      .flex-div {
        margin-bottom: 30px !important;
      }
      &:first-child {
        order: 2;
        .svg-icon {
          margin-left: 0;
        }
      }
      &.right {
        order: 2;
        .flex-div {
          justify-content: flex-start !important;
        }
        .svg-icon {
          margin-left: 0 !important;
        }
        h4 {
          order: 1;
          text-align: left !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .slider_grid {
      .flex-div {
        margin-bottom: 15px !important;
      }
    }
  }
`;
export const SliderCon = styled.div`
  .svg-icon {
    svg {
      box-shadow: 0 5px 15px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);
      border-radius: 50%;
      width: 80px;
      margin-right: 30px;
      height: 80px;
    }
  }
  @media (max-width: 991px) {
    .svg-icon {
      svg {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }
    }
  }
`;
export const MiddleSlider = styled.div`
  position: relative;
  .mySwiper {
    .swiper-image {
      img {
        max-width: 40%;
      }
    }
    .swiper-button-prev {
      left: 15%;
    }
    .swiper-button-next {
      right: 15%;
    }
    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: #000;
        font-size: 30px;
      }
    }
  }
  .circle-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    background-color: #fbc901;
    color: white;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
    font-size: 1.3rem;
    &::before {
      background: #fbc901;
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    &::after {
      background: #fbc901;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 350px;
      height: 350px;
      background: #fbc901;
      border-radius: 50%;
      z-index: -1;
      -webkit-animation: grow 1.5s ease-in-out infinite;
      animation: grow 1.5s ease-in-out infinite;
    }
  }
  @-webkit-keyframes grow {
    0% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.8, 1.8);
      transform: scale(1.8, 1.8);
      opacity: 0;
    }
  }

  @keyframes grow {
    0% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.8, 1.8);
      transform: scale(1.8, 1.8);
      opacity: 0;
    }
  }
  @media (max-width: 1440px) {
    .circle-1 {
      width: 250px;
      height: 250px;
      &::before,
      &::after {
        content: "";
        width: 250px;
        height: 250px;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 30px 0 50px;
    .mySwiper {
      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        right: 0;
      }
      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          font-size: 22px;
        }
      }
    }
    .circle-1 {
      width: 250px;
      height: 250px;
      &::before,
      &::after {
        content: "";
        width: 250px;
        height: 250px;
      }
    }
  }
  @media (max-width: 767px) {
    .circle-1 {
      width: 150px;
      height: 150px;
      &::before,
      &::after {
        content: "";
        width: 150px;
        height: 150px;
      }
    }
  }
`;

/*process section css */
export const ProcessWrapper = styled.div``;

export const ProcessInsideWrapper = styled.div`
  ${SectionTitleWrp} {
    max-width: 60%;
    margin-bottom: 80px;
  }
  .process_ul {
    align-items: center;
    &:nth-child(4) {
      .process_desc.left_block {
        border-left: 3px solid #0098d1 !important;
        &::before {
          background-color: #0098d1 !important;
        }
      }
    }
    &:nth-child(5) {
      .process_desc_li {
        .process_desc.left_block {
          border-right: 3px solid #fbd301 !important;
          &::before {
            background-color: #fbd301 !important;
          }
        }
      }
    }
    &:nth-child(6) {
      .process_desc_li {
        .process_desc.left_block {
          border-left: 3px solid #dd3a31 !important;
          &::before {
            background-color: #dd3a31 !important;
          }
        }
      }
    }
    &:nth-child(7) {
      .process_desc_li {
        .process_desc.left_block {
          border-right: 3px solid #0098d1 !important;
          &::before {
            background-color: #0098d1 !important;
          }
        }
      }
    }
    &:nth-child(odd) {
      margin: 15px 0;
      .process_image_li {
        order: 3;
      }
      .process_desc_li {
        order: 2;
        .process_desc.left_block {
          margin-right: 40px;
          border-right: 3px solid #dd3a31;
          border-left: 0;
          .border_left {
            border-radius: 26px 0 0 26px;
          }
          &::before {
            content: "";
            width: 13px;
            height: 13px;
            display: block;
            background-color: #dd3a31;
            border-radius: 100px;
            right: -7px;
            left: auto;
            position: absolute;
            top: -8px;
            z-index: 1;
          }
        }
      }
    }

    .process_desc.left_block {
      margin-left: -30px;
      border-left: 3px solid #fbd301;
      position: relative;
      padding-bottom: 110px;
      height: 100%;
      h3 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 12px;
        font-family: "PoppinsSemiBold";
      }
      .border_left {
        background: #f9f9f9;
        padding: 25px 30px;
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
      }

      &::before {
        content: "";
        width: 13px;
        height: 13px;
        display: block;
        background-color: #fbd301;
        border-radius: 100px;
        left: -8px;
        position: absolute;
        top: -8px;
        z-index: 1;
      }
    }
    .cloud_image_li {
      .cloud_image {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @media (max-width: 991px) {
      margin: 0 0 50px !important;

      img {
        width: 100px;
      }
      .process_desc.left_block {
        padding: 0;
      }
      .cloud_image_li {
        display: none;
      }
    }
    @media (max-width: 575px) {
      .process_desc.left_block {
        margin-left: 0;
        border-right: 0 !important;
        margin-top: 10px;
        .border_left {
          padding: 20px;
        }
        h3 {
          font-size: 22px;
        }
        &::before {
          right: auto !important;
          left: -8px !important;
        }
      }
      &:nth-child(odd) {
        .process_image_li {
          order: 1;
        }
        .process_desc_li {
          .process_desc.left_block {
            border-left: 3px solid #dd3a31 !important;
            border-right: 0 !important;
            margin-right: 0;
          }
          .border_left {
            border-radius: 0 26px 26px 0 !important;
          }
        }
      }
      &:nth-child(5) {
        .process_desc_li {
          .process_desc.left_block {
            border-left: 3px solid #fbd301 !important;
            border-right: 0 !important;
          }
        }
      }
      &:nth-child(7) {
        .process_desc_li {
          .process_desc.left_block {
            border-left: 3px solid #0098d1 !important;
            border-right: 0 !important;
          }
        }
      }
      img {
        width: 40px;
      }
    }
  }
  @media (max-width: 991px) {
    ${SectionTitleWrp} {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
`;

/*services section css */
export const InstriesWrapper = styled.div``;

export const IndustriesUl = styled.div`
  margin-top: 50px;
  .cover_wrap {
    border: 1px solid #cccbcb;
    padding: 20px;
    border-radius: 40px;
    margin-bottom: 30px;
    transition: 0.3s;
    &:hover {
      border-color: #000;
    }
  }
  .cover_img {
    svg {
      width: 78px;
      height: 78px;
      margin-right: 20px;
    }
  }
  .cover_title {
    h3 {
      font-size: 21px;
      line-height: 1.5;
      font-family: "PoppinsSemiBold";
    }
  }
  @media (max-width: 1440px) {
    .cover_wrap {
      padding: 16px;
      border-radius: 32px;
    }
    .cover_img {
      svg {
        width: 50px;
        height: 50px;
      }
    }
    .cover_title {
      h3 {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 991px) {
    .cover_wrap {
      padding: 10px;
      border-radius: 23px;
      margin-bottom: 20px;
    }
    .cover_title {
      h3 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 575px) {
    margin-top: 30px;
    .cover_wrap {
      border-radius: 18px;
    }
    .cover_img {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

/*technologies section */
export const TechnologyWrapper = styled.div`
  position: relative;
  padding: 100px 0 0;
  ::before {
    content: "";
    background: url(${Images.TechnologiBG}) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    background-size: 100% !important;
  }
  @media (max-width: 575px) {
    padding: 100px 0 0;
    &::before {
      background: url(${Images.HireServiceBg}) no-repeat;
    }
  }
`;

export const TitleWrapper = styled.div`
  padding: 200px 0 0;
  ${SectionTitleWrp} {
    max-width: 54%;
    h2 {
      color: #fff;
      max-width: 100%;
    }
    p {
      color: #929292;
    }
  }
  @media (max-width: 1199px) {
    padding: 90px 0 0;
  }
  @media (max-width: 991px) {
    padding: 50px 0 0;
  }
  @media (max-width: 767px) {
    ${SectionTitleWrp} {
      max-width: 100%;
    }
    .button-text {
      text-align: center !important;
    }
  }
  @media (max-width: 575px) {
    padding: 0;
    ${SectionTitleWrp} {
      max-width: 100%;
      h2 {
        color: #000;
      }
      p {
        color: #000;
      }
    }
  }
`;
export const SwiperArrow = styled.div`
  position: absolute;
  top: 55%;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
  z-index: 99;
  .overlay-btn {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    opacity: 1;
    &:hover{
      background: #DD3A31;
    }
    button {
      background: transparent;
      border: 0 !important;
      width: 58px;
      height: 58px;
      svg{
        width: 25px;
        height: 25px;
        margin-top: 5px;
        path{
          fill: #fff;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &.next {
      right: -90px;
    }
    &.prev {
      left: -90px;
    }
  }
  @media (max-width: 1700px) {
    .overlay-btn {
      &.next {
        right: -15px;
      }
      &.prev {
        left: -15px;
      }
    }
  }
  @media (max-width: 1440px) {
    top:44%;    
  }
  @media (max-width: 1199px) {
    .overlay-btn {
      &.next {
        right:0;
      }
      &.prev {
        left:0;
      }
    }
  }
  @media (max-width: 575px) {
    .overlay-btn {
      position: absolute;
      button {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
export const SliderWrapper = styled.div`
  padding-top: 120px;
  margin-left: auto;
  position: relative;
  &.service_slide {
    margin: 0 auto;
    max-width: 1500px;
    position: relative;
  }
  .swiper {
    padding: 0 15px;
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-wrapper {
    padding: 20px 0;
    .swiper-slide {
      &:nth-child(1) {
        .tech_block {
          &::before {
            border: 12px solid #fbd301;
          }
        }
      }
      &:nth-child(2) {
        .tech_block {
          &::before {
            border: 12px solid #dd3a31;
          }
        }
      }
      &:nth-child(3) {
        .tech_block {
          &::before {
            border: 12px solid #0098d1;
          }
        }
      }
      &:nth-child(4) {
        .tech_block {
          &::before {
            border: 12px solid #fbd301;
          }
        }
      }
    }
    div[data-swiper-slide-index="0"] {
      .tech_block {
        &::before {
          border: 12px solid #fbd301;
        }
      }
    }
    div[data-swiper-slide-index="1"] {
      .tech_block {
        &::before {
          border: 12px solid #dd3a31;
        }
      }
    }
    div[data-swiper-slide-index="2"] {
      .tech_block {
        &::before {
          border: 12px solid #0098d1;
        }
      }
    }
    div[data-swiper-slide-index="3"] {
      .tech_block {
        &::before {
          border: 12px solid #fbd301;
        }
      }
    }
  }
  .tech_block {
    background-color: #e7e8e7;
    text-align: center;
    padding: 60px 40px;
    border: 15px solid #fff;
    border-radius: 25px;
    position: relative;
    height: 100%;
    box-shadow: 2px 4px 22px rgb(0 0 0 / 12%), 0 3px 25px rgb(0 0 0 / 2%);

    img {
      margin: 0 auto 25px;
      width: 160px;
      height: 160px;
    }
    .img-logo {
      width: 160px;
      height: auto;
      margin: 30px auto;
    }
    h4 {
      font-size: 20px;
      font-family: "PoppinsBold";
      text-align: center;
    }
    &::before {
      content: "";
      top: -25px;
      left: 35%;
      right: -25px;
      bottom: -25px;
      border-left: 0 !important;
      border: 12px solid transparent;
      display: block;
      position: absolute;
      border-radius: 36px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: -1;
      border: 12px solid #fbd301;
    }
  }
  @media (max-width: 1600px) {
    .tech_block {
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  @media (max-width: 1440px) {
    padding-top: 40px;
    padding: 30px 20px;
    .tech_block {
      img {
        width: 130px;
        height: 130px;
      }
    }
  }
  @media (max-width: 767px) {
    .tech_block {
      padding: 15px 30px;
      img {
        width: 90px;
        height: 90px;
      }
    }
  }
  @media (max-width: 575px) {
    .tech_block {
      padding: 20px 20px;
    }
  }
`;
export const SliderContent = styled.div``;
