import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Images from '../../assets/Images'
import { ContainerLeft } from '../../styles/globalStyles'
import {
  BannerImage,
  BannerLeftCon,
  BannerleftWrapper,
} from '../../styles/Hirepages.style'
import BannerTitle from '../../comman/BannerTitle'
import MainButton from '../../comman/Button'
import { Homedata } from '../../services/data'

export const LeftSide = () => {
  return (
    <BannerLeftCon>
      <BannerTitle
        IsSectionTitle
        MainTitle={<h1>Hire The Best Android <br/> App Developers In India</h1>}
      ></BannerTitle>
      <ul>
        {Homedata?.map((item) => {
          return <li>{item.list}</li>
        })}
      </ul>
      <MainButton isTitle={true} title={"Hire Us"} />
    </BannerLeftCon>
  )
}
export default LeftSide
