import React from 'react'
import TalkExpert from './TalkExpert'

export const AndroidExportSection = () => {
  return (
    <TalkExpert
      isBtnText
      Title="Why are our clients happy with us?"
      Text="We completely understand the idea behind iPhone app development. The professional team of Android developers does their best to grant satisfaction to the client."
    />
  )
}
export default AndroidExportSection