import React from "react";
import { Container } from "react-bootstrap";
import Images from "../assets/Images";
import TitleSection from "../components/TitleSection";
import { FlowImg, HireFlowCon } from "../styles/Hirepages.style";

export const HireFlowSection = () => {
  return (
    <>     
      <HireFlowCon>
      <Container>
        <TitleSection
          title={"Hire Android App Developer Experts In 5 Easy Steps"}
        />
        <FlowImg>
          <img src={Images.HireFlowImage} class="img-fluid" />
        </FlowImg>
      </Container>
      </HireFlowCon>
    </>
  );
};
export default HireFlowSection;
