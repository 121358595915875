import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

const Layout = (props) => {
  const { children } = props
  const [active, setActive] = useState(false)
  const [position, setPosition] = React.useState({ top: 0, left: 0 })
  React.useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: 'smooth',
    })
  })

  const [visibility, setVisibility] = React.useState(false)
  const scrollTop = React.useRef()
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      window.scrollY > 200
        ? (scrollTop.current.style.display = 'flex')
        : (scrollTop.current.style.display = 'none')
    })
  })
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <Button
        onClick={() =>
          setPosition({ ...position, position: { top: 0, left: 0 } })
        }
        className="top-arrow"
        ref={scrollTop}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </Button>
    </>
  )
}

export default Layout
