import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BenifitData } from "../../services/data";
import {
  BenifitList,
  BenifitListRight,
  ServiceBenifitWrapper,
} from "../../styles/Servicespages.style";
import TitleSection from "../TitleSection";

export const ServiceBenifit = () => {
  return (
    <ServiceBenifitWrapper>
      <Container>
        <TitleSection title={"Benefits"} />
        <BenifitList>
          <Row>
            {BenifitData?.map((item, index) => {
              return (
                <>
                  <Col lg={6} className="beifit_li">
                    <div className="d-flex flex-data align-items-center">
                      <div class="benifit_img">
                        <span></span>
                      </div>
                      <BenifitListRight>
                        <h4>{item.Title}</h4>
                        <p>{item.Text}</p>
                      </BenifitListRight>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </BenifitList>
      </Container>
    </ServiceBenifitWrapper>
  );
};
export default ServiceBenifit;
