import React from 'react'
import Routers from './routers/Routers'
import 'bootstrap/dist/css/bootstrap.min.css'
import { GlobalStyle } from './styles/globalStyles.jsx'

function App() {
  return (
    <>
      <GlobalStyle />
      <Routers />
    </>
  )
}

export default App
